// General
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Control, Controller, UseFormGetValues } from "react-hook-form";
import { useI18n } from "compass-commons";
// Styles
import "./subsystemDataPanel.module.css";
// Components
import { AutoComplete, TextField } from "dms-lib";
// Models
import { SubsystemLightConnections } from "../../../../../../models/subsystems/SubsystemLightDto";
import { BrandDto } from "../../../../../../models/subsystems/BrandDto";
import { ConnectionData } from "../../../../../../models/subsystems/SubsystemDto";
import { FormErrors } from "../../models/SubsystemConnectionDataTypes";
// Store
import {
  getSubsystemModel,
  subsystemsActions,
} from "../../../../../../store/subsystems";
import { selectSubsystems, useStoreDispatch } from "../../../../../../store";
import { editionMode } from "../../../../../../store/root";

interface SubsystemDataPanelProps {
  readOnlyMode: boolean;
  displayedSubsystem: SubsystemLightConnections;
  brands: BrandDto[];
  control: Control<ConnectionData, any>;
  getValues: UseFormGetValues<ConnectionData>;
  reset: () => void;
  resetField: (name: string) => void;
  errors?: FormErrors<ConnectionData>;
}

const SubsystemDataPanel = ({
  readOnlyMode,
  displayedSubsystem,
  brands,
  control,
  getValues,
  resetField,
  reset,
  errors,
}: SubsystemDataPanelProps): JSX.Element => {
  const { t } = useI18n();

  // Redux
  const dispatch = useStoreDispatch();
  const isEditionMode = useSelector(editionMode);
  const { models, loadingModels } = useSelector(selectSubsystems);
  const subsystemModel = useSelector(getSubsystemModel);

  // brands and models cannot be updated on edit mode
  const specialFieldsReadOnly = isEditionMode || readOnlyMode;

  const resetModels = () => {
    resetField("model");
  };

  const loadModels = (brandId: string, subsystemId: string) => {
    dispatch(subsystemsActions.getModels({ brandId, subsystemId }));
  };

  const getConnectionPropertiesTemplates = async () => {
    const selectedBrand = getValues("brand");
    const selectedModel = getValues("model");

    dispatch(
      subsystemsActions.getConnectionPropertiesTemplate({
        brandId: selectedBrand.id,
        modelId: selectedModel.id,
        integrationRegistrationId: selectedModel.integrationRegistrationId,
      })
    );
  };

  // Lifecycles
  useEffect(() => {
    reset();
    if (displayedSubsystem && readOnlyMode) {
      // Only refresh if model name is empty and it is important to populate
      // from BE
      if (displayedSubsystem.brandName && !subsystemModel?.name)
        loadModels(displayedSubsystem.brandId, displayedSubsystem.id);
    }
  }, [displayedSubsystem, readOnlyMode]);

  useEffect(() => {
    reset();
  }, [subsystemModel]);

  useEffect(() => {
    if (models.length && readOnlyMode) {
      dispatch(
        subsystemsActions.getConnectionProperties(displayedSubsystem.id)
      );
    }
  }, [models.length]);

  return (
    <div className="config-subsystem-properties__content">
      <form>
        <div className="config-subsystem-properties__field-wrapper config-subsystem-connection-data__field-wrapper--width">
          <span>{t("subsystems.propertiesFields.name")}:</span>
          <div
            id="input-wrapper"
            className="config-subsystem-properties__controller"
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    data-cr="subsystem-name"
                    className="field-width"
                    size="small"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    disabled={readOnlyMode}
                    aria-label={`subsystem-name-${displayedSubsystem.name}`}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="config-subsystem-properties__field-wrapper config-subsystem-connection-data__field-wrapper--width">
          <span>{t("subsystems.propertiesFields.brand")}:</span>
          <div className="config-subsystem-properties__controller">
            <Controller
              name="brand"
              control={control}
              render={({ field: { onChange, ...field } }) => {
                return (
                  <AutoComplete
                    {...field}
                    className="field-width"
                    dataCr="subsystem-brand"
                    options={brands}
                    getOptionLabel={(option) => option?.name}
                    id={`subsystem-brand-autocomplete-${displayedSubsystem.id}`}
                    disabled={specialFieldsReadOnly}
                    placeholder={t(
                      "subsystems.propertiesFields.brandPlaceholder"
                    )}
                    onChangeCallback={(
                      data: { id: string; name: string },
                      _e
                    ) => {
                      resetModels();
                      loadModels(data?.id, displayedSubsystem?.id);
                      onChange(data || { name: "" });
                    }}
                    error={!!errors.brand}
                    errorMessage={
                      errors.brand?.name?.message || errors.brand?.message
                    }
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="config-subsystem-properties__field-wrapper config-subsystem-connection-data__field-wrapper--width">
          <span>{t("subsystems.propertiesFields.model")}:</span>
          <div className="config-subsystem-properties__controller">
            <Controller
              name="model"
              control={control}
              render={({ field: { onChange, ...field } }) => {
                return (
                  <AutoComplete
                    {...field}
                    className="field-width"
                    dataCr="subsystem-model"
                    options={models}
                    getOptionLabel={(option) => option.name}
                    id={`subsystem-model-autocomplete-${displayedSubsystem.id}`}
                    disabled={specialFieldsReadOnly}
                    loading={loadingModels}
                    loadingText={t("subsystems.models.loading")}
                    placeholder={t(
                      "subsystems.propertiesFields.modelPlaceholder"
                    )}
                    onChangeCallback={(data, _e) => {
                      onChange(data || { name: "" });
                      if (data) getConnectionPropertiesTemplates();
                    }}
                    error={!!errors.model}
                    errorMessage={
                      errors.model?.name?.message || errors.model?.message
                    }
                  />
                );
              }}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default SubsystemDataPanel;
