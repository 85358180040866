// General
import React from "react";
import { useI18n } from "compass-commons";
// Material UI
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";

interface OgDialogContentProps {
  ogName?: string;
}

const OgDialogContent = ({
  ogName = "",
}: OgDialogContentProps): JSX.Element => {
  const { t } = useI18n();
  return (
    <>
      <div className="delete-warning-modal">
        <ReportProblemRoundedIcon color="error" />
        <span>
          {t("operatorGuide.deleteModal.description", {
            label: ogName,
          })}
        </span>
      </div>
    </>
  );
};

export default OgDialogContent;
