import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaCss from "single-spa-css";
import singleSpaReact from "single-spa-react";
import { map } from "rxjs";
import { io, Theme } from "@msi/vsa-unity-api";
import { ICompassI18n, isUnity, LocalizationNS } from "compass-commons";
import { CompassI18n, defaultNS } from "compass-shared-services";
import { AppTitle, DMSTheme } from "dms-lib";
import App from "./App";
import "compass-commons/lib/css/compass-light-core.css";
import "dms-lib/lib/esm/dms-lib.css";

const compassI18n = CompassI18n(appConfig.ASSET_STORE);

// REMOVE AFTER DARK MODE IS IMPLEMENTED
if (!isUnity) {
  localStorage.setItem("mui-color-scheme-dark", "light");
}

const cssLifecycles = singleSpaCss({
  cssUrls: [],
  webpackExtractedCss: true,
});

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent(props) {
    const [mode, setMode] = React.useState(
      io.component(Theme).getValue().theme
    );
    return (
      <DMSTheme
        style={isUnity ? {} : { height: "90vh" }}
        themeObservable={io.component(Theme).pipe(
          map(({ theme }) => {
            setMode(theme);
            return theme;
          })
        )}
        mode={mode}
      >
        <AppTitle translate={compassI18n.t} localizationNS={LocalizationNS} />
        <App {...props} />
      </DMSTheme>
    );
  },
  errorBoundary(_err, _info, _props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

const reactLifecyclesMountWithCompass = async <
  T extends { CompassI18n: ICompassI18n }
>(
  props: T
): Promise<any> => {
  await compassI18n.init({ ns: defaultNS });
  await compassI18n.loadMoreTranslations(LocalizationNS.CONFIGURATION);
  // eslint-disable-next-line no-param-reassign
  props.CompassI18n = compassI18n as ICompassI18n;
  return reactLifecycles.mount(props);
};

export const bootstrap = [cssLifecycles.bootstrap, reactLifecycles.bootstrap];
export const mount = [cssLifecycles.mount, reactLifecyclesMountWithCompass];
export const unmount = [reactLifecycles.unmount, cssLifecycles.unmount];
