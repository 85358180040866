import React, { useEffect } from "react";
import "./tableView.module.css";
import Spinner from "@msi/cobalt-react/spinner";
import { useTable, usePagination } from "react-table";
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from "@mui/material";

interface TableViewProps {
  columns: Array<any>;
  data: Array<any>;
  isPaginated?: boolean;
  onRowClickCallback?: (event) => void;
  apiCallFunc?: ({ pageIndex, pageSize }) => void;
  totalDataCount?: number;
  totalPageCount?: number;
  loading?: boolean;
  rowsPerPage?: number;
  hideColumns?: Array<string>;
  scrollableMaxHeight?: number;
}

const TableView = (props: TableViewProps): JSX.Element => {
  const {
    columns,
    data,
    onRowClickCallback,
    isPaginated = true,
    apiCallFunc,
    totalPageCount,
    totalDataCount,
    loading = false,
    rowsPerPage = 10,
    hideColumns = [],
    scrollableMaxHeight = 347,
  } = props;

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: rowsPerPage,
        hiddenColumns: hideColumns,
      },
      manualPagination: !!apiCallFunc,
      pageCount: totalPageCount,
    },
    usePagination
  );

  useEffect(() => {
    if (apiCallFunc) {
      apiCallFunc({ pageIndex, pageSize });
    }
  }, [apiCallFunc, pageIndex, pageSize]);

  const pageActions = (event) => {
    if (event.currentTarget?.innerText) {
      gotoPage(Number(event.currentTarget.innerText) - 1);
    }

    if (event.currentTarget.ariaLabel === "Go to next page") {
      nextPage();
    }

    if (event.currentTarget.ariaLabel === "Go to last page") {
      gotoPage(pageCount - 1);
    }

    if (event.currentTarget.ariaLabel === "Go to previous page") {
      previousPage();
    }

    if (event.currentTarget.ariaLabel === "Go to first page") {
      gotoPage(0);
    }
  };

  const TablePaginationActions = () => {
    const theme = useTheme();

    return (
      <div
        style={{
          flexShrink: 0,
          marginLeft: theme.spacing(2.5),
        }}
      >
        <Pagination
          count={Math.ceil((totalDataCount || data.length) / pageSize)}
          onChange={pageActions}
          showFirstButton
          showLastButton
          page={pageIndex + 1}
        />
      </div>
    );
  };

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner size="large" />
        </div>
      ) : (
        <div className="table-wrapper">
          <TableContainer
            className="checkout-base-table"
            sx={{ maxHeight: scrollableMaxHeight }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              id="incidents-table"
              {...getTableProps()}
            >
              <TableHead className="dms-table-head-table-view">
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      return column.hideHeader ? null : (
                        <TableCell
                          {...column.getHeaderProps({
                            style: {
                              minWidth: column.minWidth,
                              width: column.width,
                            },
                          })}
                        >
                          {column.render("Header")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody className="table-body-table-view">
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      onClick={() => {
                        if (onRowClickCallback) {
                          onRowClickCallback(row);
                        }
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <TableCell
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.minWidth,
                                width: cell.width,
                                maxWidth: cell.maxWidth,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {isPaginated && (
            <Table>
              <TableBody>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    count={totalDataCount || data.length}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    onPageChange={() => {
                      console.log(`new page${pageIndex}`);
                    }}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableBody>
            </Table>
          )}
        </div>
      )}
    </>
  );
};

export default TableView;
