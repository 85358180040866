import * as React from "react";
import { Button, Dialog } from "dms-lib";
import { useEffect, useState } from "react";
import "./AlertDialog.css";

interface AlertDialogProps {
  title?: string;
  content?: string;
  approvalText?: string;
  disapprovalText?: string;
  isDialogOpen?: boolean;
  dialogCallback?: (open: boolean) => void;
}

const AlertDialog = (props: AlertDialogProps): JSX.Element => {
  const {
    content = "Do you confirm?",
    title = "Confirmation Dialog",
    approvalText = "Yes",
    disapprovalText = "No",
    isDialogOpen = false,
    dialogCallback,
  } = props;
  const [isOpen, setIsOpen] = useState(isDialogOpen);

  useEffect(() => {
    setIsOpen(isDialogOpen);
  }, [isDialogOpen]);

  const handleClose = () => {
    setIsOpen(false);
    if (dialogCallback) {
      dialogCallback(false);
    }
  };

  const handleCloseConfirm = () => {
    setIsOpen(false);
    if (dialogCallback) {
      dialogCallback(true);
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={isOpen}
        className="report-alert-dialog-container"
        dialogTitle={<div className="report-alert-dialog-block">{title}</div>}
        dialogContent={
          <div className="report-alert-dialog-block">{content}</div>
        }
        dialogActions={
          <div className="report-alert-dialog-block">
            <Button color="primary" variant="text" onClick={handleClose}>
              {disapprovalText}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleCloseConfirm}
            >
              {approvalText}
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default AlertDialog;
