import React, { useEffect, useState } from "react";
import { Button } from "dms-lib";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import "./supervisorBadge.module.css";
import { Alert } from "@mui/material";
import { BehaviorSubject } from "rxjs";
import { OperationIncidentInfo } from "../../../../models/notification/OperationIncidentInfo";

interface SupervisorBadgeProps {
  badgeSubject?: BehaviorSubject<string>;
  badgeErrorMessageSubject?: BehaviorSubject<string>;
  onClickCallBack?: () => void;
  operationIncidentInfoSubject?: BehaviorSubject<OperationIncidentInfo>;
}

const SupervisorBadge = (props: SupervisorBadgeProps): JSX.Element => {
  const {
    badgeSubject = null,
    badgeErrorMessageSubject = null,
    onClickCallBack = () => null,
    operationIncidentInfoSubject = null,
  } = props;
  const [errorMessage, setErrorMessage] = useState(
    badgeErrorMessageSubject?.value
  );
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const subscription = badgeSubject?.subscribe((value) => {
      if (value) {
        // eslint-disable-next-line
        console.log("badge subscription");
      }
    });

    const subsBadgeError = badgeErrorMessageSubject?.subscribe((value) => {
      if (value) {
        setErrorMessage(value);
        setAlertIsOpen(true);
        setTimeout(() => {
          setAlertIsOpen(false);
          badgeErrorMessageSubject.next(null);
        }, 4000);
      }
    });

    const operationIncidentInfo = operationIncidentInfoSubject?.subscribe(
      (val) => {
        // TODO: Should be send an incident State from the BE
        if (!val?.activationsCount) setDisabled(!val);
      }
    );

    return () => {
      subscription?.unsubscribe();
      subsBadgeError?.unsubscribe();
      operationIncidentInfo?.unsubscribe();
    };
  }, [badgeSubject, badgeErrorMessageSubject, operationIncidentInfoSubject]);

  return (
    <>
      <div style={{ display: "flex" }}>
        {alertIsOpen ? (
          <Alert
            style={{ marginRight: "5px" }}
            variant="outlined"
            severity="info"
          >
            {errorMessage}
          </Alert>
        ) : (
          <></>
        )}
        <Button
          size="small"
          variant="contained"
          color="primary"
          className="dashboards__header-btn"
          dataCr="refresh-button"
          onClick={onClickCallBack}
          disabled={disabled}
        >
          <AutorenewIcon />
        </Button>
      </div>
    </>
  );
};

export default SupervisorBadge;
