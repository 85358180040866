import React, { createRef, useState } from "react";
import { Position, EdgeProps, getSmoothStepPath } from "reactflow";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "./removableEdge.module.css";
import { Button } from "dms-lib";

const FOREIGN_OBJECT_SIZE = 32;

export interface RemovableEdgeProps extends EdgeProps {
  onEdgesRemove?: (dataIds: string[]) => void;
}

const RemovableEdge = (props: RemovableEdgeProps): JSX.Element => {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    style,
    sourcePosition = Position.Bottom,
    targetPosition = Position.Top,
    pathOptions = { borderRadius: 10 },
    markerEnd,
    onEdgesRemove,
  } = props;
  const { borderRadius } = pathOptions;
  const lineRef = createRef<SVGPathElement>();
  const [hovering, setHovering] = useState(false);

  const [path, centerX, centerY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius,
  });

  const onEdgeClick = (evt) => {
    evt.stopPropagation();
    onEdgesRemove?.([id]);
  };

  return (
    <>
      <path
        ref={lineRef}
        style={style}
        className="react-flow__edge-path edge-path-fat"
        d={path}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      />
      <path
        style={style}
        className="react-flow__edge-path edge-path"
        d={path}
        markerEnd={markerEnd}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      />
      <foreignObject
        width={FOREIGN_OBJECT_SIZE}
        height={FOREIGN_OBJECT_SIZE}
        x={centerX - FOREIGN_OBJECT_SIZE / 2}
        y={centerY - FOREIGN_OBJECT_SIZE / 2}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <div className="edge-path-button-foreign-object-div">
          {hovering && (
            <Button
              icon
              size="small"
              color="inherit"
              variant="contained"
              className="edge-path-button"
              onClick={(event) => onEdgeClick(event)}
            >
              <CloseRoundedIcon fontSize="small" color="inherit" />
            </Button>
          )}
        </div>
      </foreignObject>
    </>
  );
};

export default RemovableEdge;
