import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../operatorGuide.module.css";
import { Virtuoso } from "react-virtuoso";
import { Incident } from "compass-commons";
import { OGExecution } from "../../../model/OG/OGExecution";
import { mapTask } from "../../../helpers/taskHelpers";
import { TaskStepState } from "../../../model/OG/TaskStepState";
import { OGExecutionTask } from "../../../model/OG/OGExecutionTask";
import { ResourceCommandAdditionalInfo } from "../../../model/resource/ResourceCommand";
import OperatorGuideExecutionHeader from "./header/OperatorGuideExecutionHeader";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import {
  IncidentAADTOToOGExecutiondAAInfoLists,
  isTaskOutsideOGConfiguration,
} from "../../../helpers/oGExecutionHelper";
import { sortByProperty } from "../../../utils/Utils";
import { OGAdditionalActivations } from "../../../model/OG/IncidentAdditionalActivation";
import { ResourceMappingStateChangeData } from "../../../model/notifications/ResourceMappingStateChangeData";
import { OGExecutionTaskStepBase } from "../../../model/OG/OGExecutionTaskStepBase";
import useRequestSnapshot from "../../../hooks/useRequestSnapshot";

interface OperatorGuideExecutionProps {
  execution: OGExecution;
  resourceCommandAdditionalInfo: ResourceCommandAdditionalInfo;
  incident: Incident;
}

const { RESOURCE_MAPPING_STATE_CHANGE_HUB } = appConfig;

const OperatorGuideExecution = (
  props: OperatorGuideExecutionProps
): JSX.Element => {
  useRequestSnapshot();
  const { execution, resourceCommandAdditionalInfo, incident } = props;
  const { stateService, listenerService } = useGlobalContext();
  const { incidentAdditionalActivations, resourceMappingExecutionStateChange } =
    stateService;

  const executionListRef = useRef(null);

  const currentTask = useMemo(
    () =>
      execution?.engineTaskResponseDTO?.taskTypeEngineList.find(
        (task: OGExecutionTask) => task.current === true
      ),
    [execution?.engineTaskResponseDTO?.taskTypeEngineList]
  );

  const [additionalActivations, setAdditionalActivations] =
    useState<OGAdditionalActivations>(incidentAdditionalActivations.value);

  // 2 Types of Completed Tasks (For now):
  // - All the steps completed by the user in the OG Guide
  // - Activations that comes from the system
  const getCompletedTasks = (): OGExecutionTaskStepBase[] => {
    const activationsAsTask =
      (additionalActivations.isAdditionalActivationVisible &&
        IncidentAADTOToOGExecutiondAAInfoLists(
          additionalActivations.additionalActivations
        )) ||
      [];

    return execution.registryResponse.stepsList
      .concat(activationsAsTask)
      .sort(sortByProperty("clearedTimeStamp", true));
  };

  const CompletedTaskElement = React.memo(
    ({ task, index }: { task: OGExecutionTaskStepBase; index: number }) => {
      const className =
        task.user || isTaskOutsideOGConfiguration(task?.type)
          ? "operator-guide-completed-operator"
          : "operator-guide-completed-system";

      return (
        <div className="operator-guide-execution-list-spacing operator-guide-task-wrapper">
          <div
            key={`map-task-${index}-${task.id || index + 1}`}
            className={`${className}  compass-rounded-corner`}
            style={{ width: "100%" }}
          >
            {mapTask(
              index,
              execution.registryResponse.id,
              task,
              TaskStepState.COMPLETED,
              resourceCommandAdditionalInfo,
              incident
            )}
          </div>
        </div>
      );
    }
  );

  const getTaskElement = useCallback(
    (task, index) => <CompletedTaskElement task={task} index={index} />,
    []
  );

  const getCurrentTask = useCallback(() => {
    if (currentTask) {
      return (
        <div className="operator-guide-execution-list-spacing operator-guide-task-wrapper">
          <div
            data-cr="operator-guide-current"
            className="operator-guide-current compass-rounded-corner"
          >
            {mapTask(
              currentTask.id,
              execution.registryResponse.id,
              currentTask,
              TaskStepState.CURRENT,
              resourceCommandAdditionalInfo,
              incident
            )}
          </div>
        </div>
      );
    }
    return <></>;
  }, [currentTask?.id]);

  useEffect(() => {
    const sub = incidentAdditionalActivations.subscribe((value) => {
      setAdditionalActivations(value);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [incidentAdditionalActivations]);

  /**
   * Improve the listener function. At each element added we are listening again.
   * And if we remove one we are ending the connection to all of them.
   */
  useEffect(() => {
    listenerService.listen(
      RESOURCE_MAPPING_STATE_CHANGE_HUB,
      (msg) => {
        const resourceMappingStateChangeData: ResourceMappingStateChangeData =
          JSON.parse(msg);
        resourceMappingExecutionStateChange.next(
          resourceMappingStateChangeData
        );
      },
      "execution"
    );

    return () => {
      listenerService.stopListening(
        RESOURCE_MAPPING_STATE_CHANGE_HUB,
        "execution"
      );
    };
  }, [listenerService]);

  useEffect(() => {
    // Scroll to bottom when the current task changes
    if (currentTask) {
      executionListRef.current.scrollToIndex({
        index: "LAST",
        behavior: "auto",
      });
    }
  }, [currentTask]);

  const completedTasks = getCompletedTasks();

  return (
    <div className="operator-guide-execution">
      <OperatorGuideExecutionHeader
        additionalActivationsHeader={{
          isChecked: additionalActivations.isAdditionalActivationVisible,
          count: additionalActivations.additionalActivationsCount,
        }}
      />
      <Virtuoso
        ref={executionListRef}
        id="operator-guide-execution-list"
        className="operator-guide-execution-list"
        initialTopMostItemIndex={{ index: "LAST", behavior: "auto" }}
        totalCount={completedTasks.length}
        itemContent={(index) => getTaskElement(completedTasks[index], index)}
        followOutput="smooth"
      />
      {/* Current task outside of Virtuoso list */}
      {getCurrentTask()}
    </div>
  );
};

export default OperatorGuideExecution;
