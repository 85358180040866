var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import "@msi/cobalt-react/css/cobalt-light-core.min.css";
import "@msi/cobalt-styles/scss/overlay-prebuilt.scss";
import { LocalizationNS } from "compass-commons";
import { CompassI18n, defaultNS } from "compass-shared-services";
import App from "./App";
const lifecycles = singleSpaReact({
    React,
    ReactDOMClient,
    rootComponent: App,
    errorBoundary(_err, _info, _props) {
        // Customize the root error boundary for your microfrontend here.
        return null;
    },
});
export const mount = (props) => __awaiter(void 0, void 0, void 0, function* () {
    if (props.CompassI18n) {
        const { loadMoreTranslations } = props.CompassI18n;
        yield loadMoreTranslations(LocalizationNS.HEADER, false);
    }
    else {
        const defaultCompassI18n = CompassI18n(appConfig.ASSET_STORE);
        yield defaultCompassI18n.init({ ns: defaultNS });
        props.CompassI18n = defaultCompassI18n;
    }
    return lifecycles.mount(props);
});
export const { bootstrap, unmount } = lifecycles;
