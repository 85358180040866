import React from "react";
import { Handle, Position } from "reactflow";
import { OgBlockTypeDto } from "../../../../../../models/ogTaskTypes/OgBlockTypeDto";

interface ButtonNodeBlockProps {
  block?: OgBlockTypeDto;
}

const ButtonNodeBlock = (props: ButtonNodeBlockProps): JSX.Element => {
  const { block } = props;

  return (
    <div className="task-node-button" data-cr="task-node-button">
      {block && (
        <Handle
          id={block.button?.id || block.id}
          type="source"
          position={Position.Bottom}
          className="node-block-handles"
          isConnectable
          data-cr="node-block-handles-but"
        />
      )}
    </div>
  );
};

export default ButtonNodeBlock;
