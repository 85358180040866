// General
import React, { useEffect, useRef } from "react";
import { useI18n } from "compass-commons";
// Styles
import "./subsystemResourcesPanel.module.css";
// Components
import { Button } from "dms-lib";
// Models
import { SubsystemLightResources } from "../../../../models/subsystems/SubsystemLightDto";
// Store
import { useStoreDispatch } from "../../../../store";
import { subsystemsActions } from "../../../../store/subsystems";

interface SubsystemResourcesPanelProps {
  displayedSubsystem: SubsystemLightResources;
  disablePollingButton?: boolean;
}

const SubsystemResourcesPanel = (
  props: SubsystemResourcesPanelProps
): JSX.Element => {
  const { t } = useI18n();
  const {
    displayedSubsystem: { id: currentSubsystemId, updatingResources = true },
    disablePollingButton = false,
  } = props;

  const dispatch = useStoreDispatch();
  const RESOURCES_HEARBEAT_TIME = 1000;

  const handleResourceRefresh = () => {
    dispatch(subsystemsActions.refreshResources(currentSubsystemId));
  };

  // Create a refresh Polling
  // Purpose: Check if resources are still being updated
  // Only trigger setInterval if resources started updating
  const resourcesPolling = useRef(null);
  const subsystemPolling = async () => {
    const subsystemStatus = await dispatch(
      subsystemsActions.subsystemResourcesPolling(currentSubsystemId)
    ).unwrap();

    if (!subsystemStatus?.updatingResources) return;

    if (resourcesPolling)
      resourcesPolling.current = setTimeout(
        subsystemPolling,
        RESOURCES_HEARBEAT_TIME
      );
  };

  useEffect(() => {
    if (!disablePollingButton && updatingResources) {
      subsystemPolling();
    }
    return () => {
      clearTimeout(resourcesPolling?.current);
    };
  }, [currentSubsystemId, disablePollingButton, updatingResources]);

  return (
    <div className="config-subsystems-resources__container">
      <Button
        dataCr="update-resources-button"
        disabled={disablePollingButton || updatingResources}
        onClick={handleResourceRefresh}
        color="primary"
        variant="contained"
      >
        <span>{t("subsystems.resources.refresh")}</span>
      </Button>
    </div>
  );
};

export default React.memo(SubsystemResourcesPanel);
