import React from "react";
import { Switch, FormControlLabel, styled } from "@mui/material";

const StyledSwitch = styled(Switch)({
  "&.MuiSwitch-root": {
    padding: "12px",
  },
});

interface ResourceContentFooterProps {
  showLabels: boolean;
  handleShowLabels: (truth: boolean) => void;
  translation?: (text: string) => string;
}

function ResourceContentFooter(props: ResourceContentFooterProps): JSX.Element {
  const { showLabels, handleShowLabels, translation = (text) => text } = props;
  return (
    <div className="device-content-footer" data-cr="show-labels-toggle">
      <FormControlLabel
        label={translation("devices.labels")}
        control={
          <StyledSwitch
            checked={showLabels}
            onClick={(e: any) => handleShowLabels(e.target.checked)}
          />
        }
      />
    </div>
  );
}

export default ResourceContentFooter;
