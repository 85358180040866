// General
import React from "react";
import { useI18n } from "compass-commons";
import { useSelector } from "react-redux";

// Components
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CompassButton from "../../../commons/button";

// Styles
import "./DeleteUserModalDialog.module.css";

// Store
import { selectUsers } from "../../../../store";

interface DeleteUserModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}

const DeleteUserModal = (props: DeleteUserModalProps): JSX.Element => {
  const { t } = useI18n();
  const { loadingUsersCrud } = useSelector(selectUsers);
  const { isOpen, handleClose, handleDelete } = props;

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      data-cr="delete-user-plan-modal"
    >
      <div id="delete-user-modal">
        <DialogContent className="delete-user-modal-dialog">
          <div className="delete-warning-modal">
            <ReportProblemIcon sx={{ color: "red", fontSize: 44 }} />
            <span>{t("deleteUserModal.description")}</span>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="delete-user-dialog-actions">
            {(!loadingUsersCrud && (
              <>
                <CompassButton
                  id="cancel-button"
                  variant="primary"
                  onClick={handleClose}
                  className="button-width-fit-content"
                >
                  {t("cancel", { ns: "Shared" })}
                </CompassButton>
                <CompassButton
                  id="delete-button"
                  data-cr="confirm-user-delete"
                  variant="secondary"
                  className="button-width-fit-content"
                  onClick={handleDelete}
                >
                  {t("delete", { ns: "Shared" })}
                </CompassButton>
              </>
            )) || (
              <div
                data-cp="player-spinner"
                className="delete-user-modal-spinner-wrapper"
              >
                <CircularProgress color="inherit" size={20} />
              </div>
            )}
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DeleteUserModal;
