import React from "react";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import { OgBlockTypeDto } from "../../../../models/ogTaskTypes/OgBlockTypeDto";
import { OgTaskTypeDto } from "../../../../models/ogTaskTypes/OgTaskTypeDto";
import "./taskTypeWidget.module.css";
import {
  mapBlockType,
  getIconForTask,
} from "../../../../helpers/ogConfiguratorHelper";

interface TaskTypeWidgetProps {
  taskType?: OgTaskTypeDto;
  onMultiOptionRemove?: (blockId: string, optionId: string) => void;
}

const TaskTypeWidget = (props: TaskTypeWidgetProps): JSX.Element => {
  const { taskType, onMultiOptionRemove } = props;

  const onDragStart = (event, nodeType: OgTaskTypeDto) => {
    const ev = event;
    const nodeTypeStr = JSON.stringify(nodeType);

    ev.dataTransfer.setData("application/reactflow", nodeTypeStr);
    ev.dataTransfer.setData(
      "application/reactflow/position",
      JSON.stringify([
        event.clientX - event.currentTarget.offsetLeft,
        event.clientY - event.currentTarget.getBoundingClientRect().top,
      ])
    );
    ev.dataTransfer.effectAllowed = "move";
  };

  return (
    <>
      {taskType && (
        <div
          className="task-type-widget compass-rounded-corner compass-fade-in-smooth"
          draggable
          data-cr="task-type-widget-draggable"
          onDragStart={(event) => onDragStart(event, taskType)}
        >
          <div className="task-widget-main" data-cr="task-type-widget">
            <div className="task-widget-header">
              <div
                className="task-widget-header-left task-icon"
                data-cr="task-type-widget-title"
              >
                {getIconForTask(taskType)}
                <b data-cr={taskType?.title}>{taskType?.title}</b>
              </div>
              <DragIndicatorRoundedIcon className="task-icon" />
            </div>
            {taskType?.typeBlocks?.map((blockType: OgBlockTypeDto) =>
              mapBlockType(blockType, onMultiOptionRemove)
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TaskTypeWidget;
