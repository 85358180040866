import React from "react";
import { capitalizeFirstLetter } from "compass-commons";
import { Handle, Position } from "reactflow";
import { OgBlockTypeDto } from "../../../../../../models/ogTaskTypes/OgBlockTypeDto";

interface ConfirmationNodeBlockProps {
  block?: OgBlockTypeDto;
}

const ConfirmationNodeBlock = (
  props: ConfirmationNodeBlockProps
): JSX.Element => {
  const { block } = props;

  return (
    <div className="task-node-confirmation" data-cr="task-node-confirmation">
      <div className="task-node-block-small">
        <span>
          {capitalizeFirstLetter(block?.negativeAnswer?.text || "No")}
        </span>
        <Handle
          id={block?.negativeAnswer?.id}
          type="source"
          position={Position.Bottom}
          className="node-block-handles"
          isConnectable
          data-cr="node-block-handles-neg"
        />
      </div>
      <div className="task-node-block-small">
        <span>
          {capitalizeFirstLetter(block?.positiveAnswer?.text || "Yes")}
        </span>
        <Handle
          id={block?.positiveAnswer?.id}
          type="source"
          position={Position.Bottom}
          className="node-block-handles"
          isConnectable
          data-cr="node-block-handles-pos"
        />
      </div>
    </div>
  );
};

export default ConfirmationNodeBlock;
