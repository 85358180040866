import React from "react";
import InputBase from "@mui/material/InputBase";
import FormHelperText from "@mui/material/FormHelperText";
import MuiFormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import { Controller, useFormContext } from "react-hook-form";
import { useI18n } from "compass-commons";

const FormLabel = styled(MuiFormLabel)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: theme.typography.fontWeightBold,
}));

const Input = styled(InputBase)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  padding: theme.spacing(1.5),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,

  "&:hover": {
    borderColor: theme.palette.common.black,
  },
  "&:focus": {
    borderColor: theme.palette.common.black,
  },
  "&.Mui-error": {
    borderColor: theme.palette.error.main,
  },
}));

const defaultValues = {
  message: "",
};

type FormValues = typeof defaultValues;
type Props = { loading: boolean };

const FormAiAssistedOg = ({ loading }: Props): JSX.Element => {
  const { t } = useI18n();
  const { control } = useFormContext<FormValues>();

  return (
    <form>
      <Controller
        name="message"
        control={control}
        render={({ field, fieldState }) => (
          <>
            <FormLabel error={!!fieldState.error}>
              {t("operatorGuide.writeOg")}
            </FormLabel>
            <Input
              {...field}
              placeholder={t("operatorGuide.promptPlaceholder")}
              multiline
              error={!!fieldState.error}
              disabled={loading}
              size="small"
              fullWidth
            />
            {fieldState.error && (
              <FormHelperText error={!!fieldState.error}>
                {fieldState.error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </form>
  );
};

export { FormAiAssistedOg as default, defaultValues, FormValues };
