import React, { useState } from "react";
import SpaParcel from "single-spa-react/parcel";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { map } from "rxjs";
import { io, Theme } from "@msi/vsa-unity-api";
import { AppTitle, DMSTheme } from "dms-lib";
import { isUnity, LocalizationNS, useI18n } from "compass-commons";

import { REPORT_CHECKOUT_PATH, ROOTPATH } from "../router/route";

function HoC({ element }: { element: JSX.Element }): JSX.Element {
  const { t: translate } = useI18n();
  const [mode, setMode] = useState(io.component(Theme).value.theme);

  return (
    <DMSTheme
      style={isUnity ? {} : { height: "90vh" }}
      themeObservable={io.component(Theme).pipe(
        map(({ theme }) => {
          setMode(theme);
          return theme;
        })
      )}
      mode={mode}
    >
      <AppTitle translate={translate} localizationNS={LocalizationNS} />
      {element}
    </DMSTheme>
  );
}

export default function renderUI(ui: JSX.Element, key: React.Key): JSX.Element {
  return isUnity ? (
    <Router>
      <Routes>
        <Route path={ROOTPATH} element={<HoC element={ui} key={key} />} />
        <Route
          path={REPORT_CHECKOUT_PATH}
          element={
            <SpaParcel
              config={() => System.import("@compass/report-checkout")}
              wrapWith="section"
            />
          }
        />
      </Routes>
    </Router>
  ) : (
    <HoC element={ui} />
  );
}
