// General
import React, { useEffect, useState } from "react";
import { useI18n } from "compass-commons";
import { useSelector } from "react-redux";
// Components
import { TextField } from "@mui/material";
import { Button } from "dms-lib";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
// Models
import EdgeDeviceStatus from "../../../../models/edgeDevices/EdgeDeviceStatus";
// Store
import { edgeDevicesActions } from "../../../../store/edgeDevices";
import { selectEdgeDevices, useStoreDispatch } from "../../../../store";
import RegisterInstructionsSteps from "./RegisterInstructionsSteps";
import EdgeDeviceStatusConnectedIcon from "../../status/EdgeDeviceStatusConnectedIcon";
import EdgeDeviceStatusDisconnectedIcon from "../../status/EdgeDeviceStatusDisconnectedIcon";
import EdgeDeviceStatusUnregisteredIcon from "../../status/EdgeDeviceStatusUnregisteredIcon";
import EdgeDeviceStatusUnknownIcon from "../../status/EdgeDeviceStatusUnknownIcon";

interface SelectedEdgeDeviceDetailsBodyProps {
  edgeDeviceId: string;
  edgeDeviceName: string;
  edgeDeviceStatus: EdgeDeviceStatus;
  editMode: boolean;
}

const SelectedEdgeDeviceDetailsBody = ({
  edgeDeviceId,
  edgeDeviceName,
  edgeDeviceStatus,
  editMode,
}: SelectedEdgeDeviceDetailsBodyProps): JSX.Element => {
  const { t: translate } = useI18n();
  const dispatch = useStoreDispatch();
  const { edgeDeviceToken, loadingEdgeDeviceToken } =
    useSelector(selectEdgeDevices);
  const [name, setName] = useState(edgeDeviceName);
  const [showCopyTokenMessage, setShowCopyTokenMessage] = useState(false);
  const [showCopyTokenMessageSetTimeout, setShowCopyTokenMessageSetTimeout] =
    useState(null);
  const edgeDeviceStatusIconMap = {
    [EdgeDeviceStatus.CONNECTED]: <EdgeDeviceStatusConnectedIcon />,
    [EdgeDeviceStatus.DISCONNECTED]: <EdgeDeviceStatusDisconnectedIcon />,
    [EdgeDeviceStatus.UNREGISTERED]: <EdgeDeviceStatusUnregisteredIcon />,
    [EdgeDeviceStatus.UNKNOWN]: <EdgeDeviceStatusUnknownIcon />,
  };

  const onNameChanged = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setName(event.target.value);
  };

  useEffect(() => {
    if (showCopyTokenMessage) {
      if (showCopyTokenMessageSetTimeout) {
        clearTimeout(showCopyTokenMessageSetTimeout);
      }
      setShowCopyTokenMessageSetTimeout(
        setTimeout(() => {
          setShowCopyTokenMessage(false);
        }, 6000)
      );
    }
    return () => {
      if (showCopyTokenMessageSetTimeout)
        clearTimeout(showCopyTokenMessageSetTimeout);
    };
  }, [showCopyTokenMessage]);

  useEffect(() => {
    if (edgeDeviceToken) {
      navigator.clipboard
        .writeText(edgeDeviceToken)
        .then(() => setShowCopyTokenMessage(true));
    }
  }, [edgeDeviceToken]);

  const onCopyTokenClick = () => {
    setShowCopyTokenMessage(false);
    dispatch(edgeDevicesActions.getEdgeDeviceToken(edgeDeviceId));
  };

  return (
    <div className="selected-edge-device-details-panel-body">
      <div className="selected-edge-device-details-panel-body-input-group">
        <span className="selected-edge-device-details-panel-body-input-group-label">
          {translate("name")}:
        </span>
        {editMode ? (
          <TextField value={name} onChange={onNameChanged} />
        ) : (
          <div className="selected-edge-device-details-panel-body-input-group-div">
            <span className="selected-edge-device-details-panel-body-input-group-div-span">
              {edgeDeviceName}
            </span>
          </div>
        )}
      </div>
      <div className="selected-edge-device-details-panel-body-status">
        {edgeDeviceStatusIconMap[EdgeDeviceStatus[edgeDeviceStatus]] ??
          edgeDeviceStatusIconMap[EdgeDeviceStatus.UNKNOWN]}
        <span>
          {translate(
            `edgeDevices.statusList.${edgeDeviceStatus.toLowerCase()}`
          )}
        </span>
      </div>
      <div className="selected-edge-device-details-panel-body-copy-token">
        <Button
          dataCr="copy-edge-device-token"
          className="selected-edge-device-details-panel-body-copy-token-button"
          color="primary"
          variant="contained"
          size="medium"
          onClick={onCopyTokenClick}
          disabled={loadingEdgeDeviceToken}
        >
          {translate("edgeDevices.token.copy")}
          <CopyAllRoundedIcon />
        </Button>
        {showCopyTokenMessage && (
          <span>{translate("edgeDevices.token.copied")}</span>
        )}
      </div>
      <div className="selected-edge-device-details-panel-body-instructions">
        <span className="selected-edge-device-details-panel-body-instructions-title">
          {translate("edgeDevices.registerInstructions.title")}
        </span>
        <div className="selected-edge-device-details-panel-body-instructions-steps">
          <RegisterInstructionsSteps />
        </div>
      </div>
    </div>
  );
};

export default SelectedEdgeDeviceDetailsBody;
