// General
import React, { useCallback, useEffect, useState, useRef } from "react";
import { FloorPlanImage, SiteFloorPlan, useI18n } from "compass-commons";
import { useSelector } from "react-redux";
// Styles
import { CircularProgress, TextField } from "@mui/material";
import "./floorPlanConfigurator.module.css";
// Components
import { Button } from "dms-lib";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import FloorPlanListPanel from "./FloorPlanListPanel";
import CompassDialog from "../commons/dialog";
import FloorPlanDialogActions from "./components/floorPlanDialogActions";
// Contexts
import { useStateContext } from "../../contexts/StateContext";
// Services
import StateService from "../../services/StateService";
import FloorPlanService from "../../services/FloorPlanService";
// Context
import { useTabActionsContext } from "../../contexts/TabActionsContext";
// Hooks
import useTabActions from "../../hooks/useTabActions";
// Store
import { selectRoot, selectSites } from "../../store";
import {
  ConfigEditionModes,
  ConfigModes,
  editionMode,
  readOnlyMode,
} from "../../store/root";
import { selectIsPlaceholderSite } from "../../store/sites";
// Models
import { FloorPlansDto } from "../../models/floorPlans/FloorPlanDto";
import FloorPlanDialogContent from "./components/floorPlanDialogContent";

const FloorPlanConfigurator = (): JSX.Element => {
  const { t: translate } = useI18n();
  const VALID_FILE_TYPES = ".png, .jpg, .jpeg";

  // REDUX
  const { configEditMode, configMode } = useSelector(selectRoot);
  const isReadOnlyMode = useSelector(readOnlyMode);
  const { selectedSiteId: siteId } = useSelector(selectSites);
  const isPlaceholderSite = useSelector(selectIsPlaceholderSite);
  const isEdititionMode = useSelector(editionMode);

  // Actions Context
  const { setConfigMainButtons, setEditActionsAllowed } =
    useTabActionsContext();

  // Hooks
  const { setReadOnlyMode, setEditMode } = useTabActions();

  const stateService: StateService = useStateContext();
  const { alertSubject } = stateService;
  const [floorPlanList, setFloorPlanList] = useState([]);
  const [currentFloorPlanId, setCurrentFloorPlanId] = useState(null);
  const [currentFloorPlan, setCurrentFloorPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editingFloorPlan, setEditingFloorPlan] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fileTooLargeError, setFileTooLargeError] = useState(false);

  // Important because of concurrency
  const oldSiteRef = useRef(siteId);

  const errorCallback = useCallback((msg: string) => {
    if (msg) {
      alertSubject.next({ title: msg });
    }
  }, []);

  const resetStateValues = (keepFloorPlan = false) => {
    if (!keepFloorPlan) {
      setCurrentFloorPlanId(null);
      setCurrentFloorPlan(null);
    }
    setIsLoading(false);
    setReadOnlyMode();
  };

  const getFloorPlanMedia = async (floor: SiteFloorPlan) => {
    if (floor) {
      const newSelectedFloor = floor;
      if (!newSelectedFloor.floorPlanImage) {
        newSelectedFloor.floorPlanImage = {} as FloorPlanImage;
      }
      try {
        newSelectedFloor.floorPlanImage.content =
          await FloorPlanService.getFloorPlanImage(siteId, newSelectedFloor.id);
      } catch (e) {
        errorCallback(translate("floorPlans.failedToLoadImage"));
      }

      return newSelectedFloor;
    }
    return floor;
  };

  const updateFloorPlanList = (floorPlans: FloorPlansDto) => {
    const compareFloorPlans = (a, b) => (a.id as number) - (b.id as number);

    // Use slice not to change the current floorplans array
    setFloorPlanList(
      (floorPlans.length && floorPlans.slice().sort(compareFloorPlans)) || []
    );
  };

  const loadSite = (
    id: number | string,
    overrideSelectedFloorId: string | number = null
  ) => {
    FloorPlanService.getFloorPlansBySiteId(id)
      .then((fp) => {
        if (fp) {
          updateFloorPlanList(fp);
          if (fp.length > 0) {
            if (overrideSelectedFloorId) {
              setCurrentFloorPlanId(overrideSelectedFloorId);
            } else {
              setCurrentFloorPlanId(fp[0].id);
            }
          }
        }
      })
      .catch(() => errorCallback(translate("site.failToLoad")));
  };

  const loadFloorPlan = (id: number | string) => {
    setIsLoading(true);
    FloorPlanService.getFloorPlanById(oldSiteRef.current, id)
      .then(async (floor) => {
        const newFloor = await getFloorPlanMedia(floor);
        // Due to racing conditions, and the fact that useState is async we have to create a ref
        // to double check if the state changed meanwhile
        if (oldSiteRef.current !== siteId) return;
        setCurrentFloorPlan(newFloor);
        setEditingFloorPlan({ ...newFloor });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        errorCallback(translate("floorPlans.failedToLoadFloorPlan"));
      });
  };

  const deleteFloor = (floorId: number | string) => {
    FloorPlanService.deleteFloorPlan(siteId, floorId).then(() => {
      const newFloorList = floorPlanList.filter((fp) => fp.id !== floorId);
      setCurrentFloorPlanId(
        newFloorList?.length > 0 ? newFloorList[0].id : null
      );
      setFloorPlanList(newFloorList);
    });
  };

  const createFloor = (newFloorPlan: SiteFloorPlan) => {
    FloorPlanService.createFloorPlan(siteId, newFloorPlan).then(
      (createdFloorPlan) => {
        setIsLoading(true);
        loadSite(siteId, createdFloorPlan.id);
        setEditMode();
      }
    );
  };

  const uploadFloorPlanImage = (
    floorPlan: SiteFloorPlan,
    id: string | number
  ) => {
    const floorPlanRef = floorPlan;
    if (floorPlan?.floorPlanImage?.name && floorPlan?.floorPlanImage?.content) {
      let imageName = "imgName";
      const imageType = floorPlan.floorPlanImage.name.slice(
        floorPlan.floorPlanImage.name.lastIndexOf(".")
      );
      imageName = imageName.concat(imageType);
      floorPlan.floorPlanImage.name = imageName;
      const image = { ...floorPlan.floorPlanImage };
      setIsLoading(true);
      FloorPlanService.uploadFloorPlanImage(siteId, id, image)
        .then(() => {
          floorPlanRef.floorPlanImage = image;
          resetStateValues(true);
        })
        .catch(() => {
          floorPlanRef.floorPlanImage = null;
          editingFloorPlan.floorPlanImage = null;
          resetStateValues();
        });
    } else {
      resetStateValues();
    }
  };

  const updateFloor = () => {
    if (editingFloorPlan) {
      FloorPlanService.updateFloorPlan(
        siteId,
        editingFloorPlan.id,
        editingFloorPlan
      )
        .then((newFloor) => {
          currentFloorPlan.name = newFloor.name;
          currentFloorPlan.floorPlanImage = editingFloorPlan.floorPlanImage;
          const floorIdentifier = floorPlanList.find(
            (floorId) => floorId.id === newFloor.id
          );
          if (floorIdentifier) {
            floorIdentifier.name = newFloor.name;
          }
          uploadFloorPlanImage(currentFloorPlan, newFloor.id);
        })
        .catch(() => {
          resetStateValues();
        });
    }
  };

  const handleSelect = (floorPlanId: number | string) => {
    if (floorPlanId) {
      setCurrentFloorPlanId(floorPlanId);
    }
  };

  const handleDelete = () => {
    if (currentFloorPlanId) {
      setDeleteModalOpen(true);
    }
  };

  const handleAdd = () => {
    const newFloorPlan: SiteFloorPlan = {
      name: translate("floorPlans.newFloorPlan"),
    } as SiteFloorPlan;
    createFloor(newFloorPlan);
  };

  const handleCancel = () => {
    setEditingFloorPlan({ ...currentFloorPlan });
    setReadOnlyMode();
  };

  const handleSave = () => {
    if (currentFloorPlan) {
      updateFloor();
    } else {
      setReadOnlyMode();
    }
  };

  useEffect(() => {
    oldSiteRef.current = siteId;
    if (siteId && !isPlaceholderSite) loadSite(siteId);
    return () => {
      resetStateValues();
    };
  }, [siteId, isPlaceholderSite]);

  useEffect(() => {
    if (currentFloorPlanId) {
      loadFloorPlan(currentFloorPlanId);
    }
  }, [currentFloorPlanId]);

  function NoImageAvailable(): JSX.Element {
    return (
      <div data-cr="floor-plan-no-image" className="floor-plan-no-image">
        {translate("floorPlans.noFloorPlanImage")}
      </div>
    );
  }

  function NoFloorPlanSelected(): JSX.Element {
    return (
      <div data-cr="floor-plan-no-floor" className="floor-plan-no-image">
        {translate("floorPlans.noFloorPlanSelected")}
      </div>
    );
  }

  const getFloorPlanImage = (isEditing = false) => {
    let floorUrl = null;
    if (isEditing && editingFloorPlan?.floorPlanImage?.content) {
      floorUrl = URL.createObjectURL(editingFloorPlan.floorPlanImage.content);
    }
    if (!isEditing && currentFloorPlan?.floorPlanImage?.content) {
      floorUrl = URL.createObjectURL(currentFloorPlan.floorPlanImage.content);
    }
    if (floorUrl) {
      return (
        <img
          data-cr="floor-plan-image"
          className="floor-plan-image"
          src={floorUrl}
          alt={translate("floorPlans.floorPlan")}
        />
      );
    }
    return <NoImageAvailable />;
  };

  const handleFloorPlanNameChange = (ev) => {
    if (ev.target.value) {
      editingFloorPlan.name = ev.target.value;
    }
  };

  const setFileTooLarge = () => {
    setFileTooLargeError(true);
    setTimeout(() => setFileTooLargeError(false), 6000);
  };

  const createImageFromArrayBuffer = (
    arrayBuffer: ArrayBuffer,
    fileType: string,
    callback: (isValid: boolean) => void
  ): void => {
    const img = new Image();
    img.onload = () => callback(true);
    img.onerror = () => callback(false);
    const base64String = btoa(
      new Uint8Array(arrayBuffer).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    const dataURI = `data:${fileType};base64,${base64String}`;
    img.src = dataURI;
  };

  const isImageValidType = (
    file: File,
    callback: (isValid: boolean) => void
  ): void => {
    if (file.type === "image/png" || file.type === "image/jpeg") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result as ArrayBuffer;
        const arr = new Uint8Array(arrayBuffer).subarray(0, 4);
        const header = arr.reduce((acc, byte) => acc + byte.toString(16), "");
        // GIF headers start with 47 49 46 38
        if (header.startsWith("47494638")) {
          callback(false);
        } else {
          createImageFromArrayBuffer(arrayBuffer, file.type, callback);
        }
      };
      reader.onerror = () => callback(false);
      reader.readAsArrayBuffer(file);
    } else {
      callback(false);
    }
  };

  const handleUploadImage = (ev) => {
    if (ev?.target?.files?.length > 0) {
      const file: File = ev.target.files[0];
      isImageValidType(file, (isValid) => {
        if (isValid) {
          const fileSizeLimitBytes = 4 * 1024 * 1024; // 4MiB
          if (file?.size < fileSizeLimitBytes) {
            const blob: Blob = new Blob([file], { type: file.type });
            const newEditingFloorPlan = { ...editingFloorPlan };
            newEditingFloorPlan.floorPlanImage = {
              content: blob,
              name: file.name,
            } as FloorPlanImage;
            setEditingFloorPlan(newEditingFloorPlan);
          } else {
            setFileTooLarge();
          }
        }
      });
    }
  };

  function FloorPanelEdit() {
    return (
      <div data-cr="floor-plan-edit-panel" className="floor-plan-edit-form">
        <span className="floor-plan-edit-form-header">
          {currentFloorPlan?.name}
        </span>
        <div className="floor-plan-edit-form-main">
          <div className="floor-plan-edit-form-inputs">
            <div
              className="floor-plan-form-inputs-item-list"
              style={{ alignItems: "flex-end" }}
            >
              <span className="floor-plan-form-inputs-item">
                {translate("name", { ns: "Shared" })}:{" "}
              </span>
              <span className="floor-plan-form-inputs-item">
                {translate("image", { ns: "Shared" })}:{" "}
              </span>
            </div>
            <div className="floor-plan-form-inputs-item-list">
              <TextField
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={currentFloorPlan?.name || "Insert floor plan name"}
                onChange={handleFloorPlanNameChange}
                className="floor-plan-form-inputs-item"
                data-cr="floor-plan-edit-name-input"
                inputProps={{ maxLength: 50 }}
                defaultValue={editingFloorPlan?.name}
                fullWidth
              />
              <div className="floor-plan-form-upload-div">
                <div>
                  <Button
                    dataCr="upload-floor-plan"
                    color="primary"
                    variant="contained"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <span>{translate("floorPlans.uploadFile")}</span>
                    <FileUploadRoundedIcon />
                  </Button>
                  <input
                    id="fileInput"
                    onChange={handleUploadImage}
                    type="file"
                    className="floor-plan-upload-input"
                    data-cr="floor-plan-edit-image-input"
                    accept={VALID_FILE_TYPES}
                  />
                </div>
                <span className="failure">
                  {fileTooLargeError && translate("floorPlans.fileTooLarge")}
                </span>
              </div>

              <div className="floor-plan-edit-form-image-guide">
                <span>
                  {translate("floorPlans.floorPlanImageRequirements1")}
                </span>
                <span>
                  {translate("floorPlans.floorPlanImageRequirements2")}
                </span>
                <span>
                  {translate("floorPlans.floorPlanImageRequirements3")}
                </span>
              </div>
            </div>
          </div>
          <div className="floor-plan-edit-form-image">
            {getFloorPlanImage(true)}
          </div>
        </div>
      </div>
    );
  }

  function FloorPlanContentImage() {
    return (
      <>
        {currentFloorPlan ? (
          <div className="floor-plan-image-div">{getFloorPlanImage()}</div>
        ) : (
          <NoFloorPlanSelected />
        )}
      </>
    );
  }

  function FloorPlanContent() {
    return (
      <div
        data-cr="floor-plan-content"
        className="floor-plan-content config-content compass-rounded-corner"
      >
        {isLoading && (
          <div data-cp="player-spinner" className="config-loading">
            <CircularProgress color="inherit" />
          </div>
        )}
        {!isLoading && (
          <>
            {isEdititionMode && floorPlanList.length ? (
              <FloorPanelEdit />
            ) : (
              <FloorPlanContentImage />
            )}
          </>
        )}
      </div>
    );
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
    setReadOnlyMode();
  };

  const confirmDelete = () => {
    closeDeleteModal();
    deleteFloor(currentFloorPlanId);
  };

  const strategyConfigMode = Object.freeze({
    [ConfigModes.CREATE]: handleAdd,
    [ConfigModes.DELETE]: handleDelete,
  });

  const strategyConfigEditionMode = Object.freeze({
    [ConfigEditionModes.CANCEL]: handleCancel,
    [ConfigEditionModes.SAVE]: handleSave,
  });

  /**
   * Activate Specific tab buttons
   */
  const initTabActionsConfig = () => {
    setConfigMainButtons({
      showCreateButton: true,
      showEditButton: !!floorPlanList.length,
      showDeleteButton: !!floorPlanList.length,
    });
    setEditActionsAllowed(true);
  };

  useEffect(() => {
    initTabActionsConfig();
  }, [floorPlanList]);

  useEffect(() => {
    strategyConfigMode[configMode]?.();
  }, [configMode]);

  useEffect(() => {
    if (!isReadOnlyMode && currentFloorPlanId)
      strategyConfigEditionMode[configEditMode]?.();
  }, [configEditMode]);

  return (
    <>
      <div data-cr="floor-plan-configurator" className="config-main">
        <FloorPlanListPanel
          floors={floorPlanList}
          selectedFloorPlanId={currentFloorPlanId}
          selectFloorPlanCallback={handleSelect}
        />
        <FloorPlanContent />
      </div>
      <CompassDialog
        data-cr="delete-floor-plan-modal"
        onClose={closeDeleteModal}
        dialogState={deleteModalOpen}
        dialogContent={<FloorPlanDialogContent />}
        dialogActions={
          <FloorPlanDialogActions
            onCancel={closeDeleteModal}
            onSuccess={confirmDelete}
          />
        }
      />
    </>
  );
};

export default FloorPlanConfigurator;
