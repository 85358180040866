import React, { useEffect, useState } from "react";
import { useI18n } from "compass-commons";
import { AutoComplete } from "dms-lib";
import { IncidentTypeDTO } from "../../../../models/filters/IncidentTypeDTO";
import DataExplorerService from "../../../../services/DataExplorerService";
import "./incidentTypes.module.css";

interface IncidentTypesProps {
  onChangeCallback: (selectedIncidentTypes: IncidentTypeDTO[]) => void;
  selected: string[];
}

const IncidentTypes = (props: IncidentTypesProps): JSX.Element => {
  const { t: translate } = useI18n();
  const { onChangeCallback, selected } = props;
  const [incidentTypesData, setIncidentTypesData] =
    useState<IncidentTypeDTO[]>(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const getIncidentTypes = async () => {
    try {
      setIncidentTypesData(await DataExplorerService.getIncidentTypes());
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(translate("filters.failedToLoadIncidentTypes"));
      setIncidentTypesData(null);
    }
  };

  useEffect(() => {
    getIncidentTypes();
  }, []);

  return (
    incidentTypesData && (
      <>
        <div className="incident-types-root">
          <div className="incident-types-col-2" />
          <div className="incident-types-col-10">
            <span className="dms-type-label">Incident type:</span>
            <AutoComplete
              dataCr="data-explorer-incident-types"
              id="data-explorer-incident-types"
              placeholder={translate("filters.incidentTypes")}
              value={incidentTypesData.filter((incidentType) =>
                selected.includes(incidentType.name)
              )}
              options={incidentTypesData}
              getOptionLabel={(option: IncidentTypeDTO) => option.name}
              onChangeCallback={onChangeCallback}
              multiple
            />
          </div>
        </div>
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
      </>
    )
  );
};

export default IncidentTypes;
