import {
  InfoFieldTypes,
  ReportCart,
  ReportCartItem,
  ReportCartStateEnum,
  baseColorsMap,
  InfoFieldPhoto,
  InfoField,
} from "compass-commons";
import createDateFromString from "../helpers/createDateFromString";
import ReportCartService from "../services/ReportCartService";
import { WC_SELECTOR } from "../models/WebComponent";

const { ENV } = appConfig;

const getDocument = (document: Document): Document => {
  if (!isDMS) {
    const shadowElement = document.getElementsByTagName(WC_SELECTOR)[0];
    if (shadowElement && shadowElement?.shadowRoot) {
      return shadowElement.shadowRoot as unknown as Document;
    }
  }
  return document;
};

const getTimeFromTimestamp = (timestamp: string) => {
  if (timestamp) {
    const splitTime = timestamp.split("T");
    if (splitTime.length === 2 && splitTime[1].length >= 8) {
      return splitTime[1].substring(0, 8);
    }
  }
  return "";
};

const getDateAndTimeFromTimestamp = (timestamp: string) => {
  if (timestamp) {
    const splitTime = timestamp.split("T");
    if (splitTime.length === 2 && splitTime[1].length >= 8) {
      return `${splitTime[0]} ${splitTime[1].substring(0, 8)}`;
    }
  }
  return "";
};

const getBaseColor = (priority: string): string => {
  return priority != null ? baseColorsMap.get(priority.toLowerCase()) : "black";
};

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getUrl"] }] */
const getUrl = (url, uriPath): string => {
  let newUrl = url;
  Object.entries(uriPath).forEach(([key, value]) => {
    newUrl = newUrl.replace(`(${key})`, value);
  });
  return newUrl;
};

const getFormattedWithZero = (value: string | number): string => {
  if (value != null) {
    let valueString = value.toString();

    if (valueString.length === 1) {
      valueString = `0${valueString}`;
    }
    return valueString;
  }
  return "00";
};

const getStringFromDate = (date: Date, withLineBreak = false): string => {
  if (date != null) {
    const day = `${getFormattedWithZero(
      date.getUTCDate()
    )}/${getFormattedWithZero(date.getUTCMonth() + 1)}/${getFormattedWithZero(
      date.getFullYear()
    )}`;
    const hour = `${getFormattedWithZero(
      date.getHours()
    )}:${getFormattedWithZero(date.getMinutes())}:${getFormattedWithZero(
      date.getSeconds()
    )}`;

    if (withLineBreak) {
      return `${day}\n${hour}`;
    }
    return `${day} ${hour}`;
  }
  return "";
};

const mapStringDateToTimezone = (
  dateString: string,
  timezone = null,
  withLinebreak = false
): string => {
  const date: Date = createDateFromString(dateString);
  // TODO here map for timezone
  return getStringFromDate(date, withLinebreak);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const copyObject = (objectToCopy) => {
  return JSON.parse(JSON.stringify(objectToCopy));
};

const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const getLoggedUser = (): string => {
  const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));
  let name = "User";

  if (accountInfo && accountInfo.account) {
    const firstName =
      accountInfo.account.firstName != null
        ? accountInfo.account.firstName
        : null;
    const lastName =
      accountInfo.account.lastName != null
        ? accountInfo.account.lastName
        : null;
    const username =
      accountInfo.account.username != null
        ? accountInfo.account.username
        : "Username";
    name =
      firstName && lastName !== null ? `${firstName} ${lastName}` : username;
  }
  return name;
};

const isSafeString = (str: string) => {
  const regEx = /[\p{Letter}\p{Mark}\s]+/gu;
  return str && str.match(regEx);
};

const createReportCart = async (
  withTimestamp = Boolean(false)
): Promise<ReportCart> => {
  const newReportCart = new ReportCart();
  const currentDate = getStringFromDate(new Date());
  newReportCart.name = `Report`;
  if (withTimestamp) {
    newReportCart.name += `_${currentDate}`;
  }
  newReportCart.elementList = new Array<ReportCartItem>();
  newReportCart.status = ReportCartStateEnum.ACTIVE;
  return ReportCartService.createCart(newReportCart);
};

const normalizeCart = (cart: ReportCart): ReportCart => {
  const normalizedCart = { ...cart } as ReportCart;
  if (normalizedCart.name == null) {
    normalizedCart.name = "Report";
  }
  const sortedCartItems = normalizedCart.elementList;
  sortedCartItems.sort((a, b) => (a.position > b.position ? 1 : -1));

  normalizedCart.elementList = sortedCartItems.map((item, index) => {
    const sortedItem = {
      ...item,
      position: index,
    };
    if (!item.id || item.id === 0) {
      sortedItem.id = Math.floor((1 + Math.random()) * 0x10000);
      // TODO CHECK WITH BACKEND TO NOT SEND ID AS ZERO
    }
    return sortedItem;
  });

  return normalizedCart;
};

/**
 * converts a base64 encoded data url SVG image to a PNG image
 * @param svgImageSource image source
 * @param width target width in pixel of PNG image
 * @return {Promise<String>} resolves to png data url of the image
 */
function base64SvgToBase64Png(
  svgImageSource: string,
  width: number
): Promise<string> {
  return new Promise((resolve) => {
    const img = getDocument(document).createElement("img");
    img.crossOrigin = "*";
    img.onload = () => {
      getDocument(document).body.appendChild(img);
      const canvas = getDocument(document).createElement("canvas");
      const ratio = img.clientWidth / img.clientHeight || 1;
      getDocument(document).body.removeChild(img);
      canvas.width = width;
      canvas.height = width / ratio;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      try {
        const data = canvas.toDataURL("image/png");
        resolve(data);
      } catch (e) {
        resolve(null);
      }
    };
    img.onerror = () => {
      resolve(null);
    };
    img.src = svgImageSource;
  });
}

function getBase64FromBlob(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}

function getFileExtension(filename: string): string {
  return filename.split(".").pop();
}

function isValidEmail(emailInput: string) {
  const emailRgx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return emailInput && emailInput.match(emailRgx);
}

/**
 * Take a function and return a function that is like the initial function.
 * The returned function is invoked _once_ after repeated calls within the `delay`
 * @example
 * const hello = debounce(() => console.log('Hello World'), 300)
 * hello()
 * hello()
 * hello()
 * // 300ms later -> 'Hello World' (once!)
 */
const debounce = (
  callbackFunc: any,
  timeout = 300
): ((...args: any[]) => void) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callbackFunc.apply(this, args);
    }, timeout);
  };
};

export const hasMedia = (infoField: InfoField): infoField is InfoFieldPhoto =>
  infoField.infoFieldType === InfoFieldTypes.PHOTO &&
  !!(infoField as InfoFieldPhoto).mediaFile;

export const printError = (error: string): void => {
  if (!ENV.startsWith("prod")) console.error(error);
};

export {
  getTimeFromTimestamp,
  getDateAndTimeFromTimestamp,
  getBaseColor,
  getUrl,
  getFormattedWithZero,
  getStringFromDate,
  mapStringDateToTimezone,
  copyObject,
  capitalizeFirstLetter,
  getLoggedUser,
  isSafeString,
  normalizeCart,
  createReportCart,
  base64SvgToBase64Png,
  getFileExtension,
  isValidEmail,
  debounce,
  getBase64FromBlob,
  getDocument,
};
