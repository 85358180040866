import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// General
import React from "react";
// Material UI
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
const CompassDialog = ({ dialogState, onClose, dialogContent, dialogActions, }) => {
    return (_jsxs(Dialog, Object.assign({ open: dialogState, onClose: onClose }, { children: [dialogContent ? _jsx(DialogContent, { children: dialogContent }) : null, dialogActions ? _jsx(DialogActions, { children: dialogActions }) : null] })));
};
export default CompassDialog;
