// General
import React, { useEffect, useState } from "react";
import { useI18n } from "compass-commons";
// Components
import { AutoComplete } from "dms-lib";
import LocationOnRounded from "@mui/icons-material/LocationOnRounded";
// Styles
import "./sites.module.css";
// Services
import DataExplorerService from "../../../../services/DataExplorerService";
// Models
import { SiteTreeNodeDTO } from "../../../../models/filters/SiteTreeNodeDTO";
import { SitesOptions } from "../../../../models/filters/SitesOptions";

interface SitesProps {
  onChangeCallback: (selectedSites: SitesOptions[]) => void;
  selected?: string[];
  failedToLoadCallback?: () => void;
}

const Sites = ({
  onChangeCallback,
  failedToLoadCallback = () => {},
  selected = [],
}: SitesProps): JSX.Element => {
  const { t: translate } = useI18n();

  const [sitesData, setSitesData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const getSitesTree = (
    sitesMap: any,
    childSites: SiteTreeNodeDTO[],
    parentSite: string,
    currentPath: string,
    depth: number
  ) => {
    let newSitesMap = { ...sitesMap };
    childSites.forEach((child) => {
      newSitesMap[child.id] = {
        label: `${currentPath}${child.name}`,
        parentSite,
        childSites: child.childSites.map((site) => site.id),
      };
      if (child.childSites.length > 0) {
        newSitesMap = getSitesTree(
          newSitesMap,
          child.childSites,
          child.id,
          `${currentPath}${child.name} / `,
          depth + 1
        );
      }
    });
    return newSitesMap;
  };

  const getSites = async () => {
    try {
      const sitesTree: SiteTreeNodeDTO = await DataExplorerService.getSites();
      const sitesMap = {
        [sitesTree.id]: {
          label: `${sitesTree.name}`,
          parentSite: null,
          childSites: sitesTree.childSites.map((site) => site.id),
        },
      };
      setSitesData(
        getSitesTree(sitesMap, sitesTree.childSites, sitesTree.id, "/ ", 0)
      );
      setErrorMessage(null);
    } catch (error) {
      if (failedToLoadCallback) {
        failedToLoadCallback();
      }
      setSitesData(null);
    }
  };

  const getSelectedSites = (
    selectedSites: SitesOptions[],
    forceChildSelection: boolean
  ) => {
    const selectedSitesAndChild = selectedSites.reduce((array, site) => {
      if (
        (!selected.includes(site.id as string) || forceChildSelection) &&
        sitesData[site.id].childSites?.length > 0
      ) {
        sitesData[site.id].childSites.reduce(
          (childrenArray: SitesOptions[], childSite: string) => {
            childrenArray.push(
              ...getSelectedSites(
                [
                  {
                    id: childSite,
                    label: sitesData[childSite].label,
                  },
                ],
                true
              )
            );
            return childrenArray;
          },
          array
        );
      }
      return [site, ...array];
    }, [] as SitesOptions[]);

    return selectedSitesAndChild.filter(
      (selectedSite, index) =>
        index ===
        selectedSitesAndChild.findIndex((o) => o.id === selectedSite.id)
    );
  };

  useEffect(() => {
    getSites();
  }, []);

  return (
    sitesData && (
      <>
        <div className="row" data-cr="de-sites">
          <div className="col-2">
            <i className="compass-component-icon">
              <LocationOnRounded />
            </i>
          </div>
          <div className="col-10">
            <AutoComplete
              id="sites-filter"
              placeholder={translate("filters.sites")}
              getOptionLabel={(option) => option.label}
              options={Object.keys(sitesData).map(
                (siteId) =>
                  ({
                    id: siteId,
                    label: sitesData[siteId]?.label,
                  } as SitesOptions)
              )}
              value={selected.map(
                (siteId) =>
                  ({
                    id: siteId,
                    label: sitesData[siteId]?.label,
                  } as SitesOptions)
              )}
              onChangeCallback={(_selectedOption) =>
                onChangeCallback(
                  getSelectedSites(
                    Array.isArray(_selectedOption)
                      ? _selectedOption
                      : [_selectedOption],
                    false
                  )
                )
              }
              multiple
            />
          </div>
        </div>
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
      </>
    )
  );
};

export default Sites;
