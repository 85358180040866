import React from "react";
import "./operatorGuideHeader.module.css";
import { getMinimalIncidentId, useI18n } from "compass-commons";
import LocationOnIcon from "@mui/icons-material/LocationOnRounded";
import { IncidentIcon } from "dms-lib";
import { IncidentHeaderDescription } from "../../../model/incident/IncidentHeaderDescription";
import OperatorGuideBadge from "./OperatorGuideBadge";

interface OperatorGuideHeaderProps {
  incidentHeaderDescription: IncidentHeaderDescription;
  className?: string;
}

const OperatorGuideHeader = (props: OperatorGuideHeaderProps): JSX.Element => {
  const { incidentHeaderDescription: incident, className } = props;
  const elementClassName = `${
    (className && `${className} `) || ""
  }operator-guide-header`;

  const { t: translate } = useI18n();

  return (
    <div className={elementClassName}>
      <div className="operator-guide-header__main">
        <div className="operator-guide-header__data-left">
          <OperatorGuideBadge triggerType={incident.triggerType}>
            <IncidentIcon
              src={incident.iconPath}
              priority={incident.priority}
              size="extra-large"
              className="incident-fit-icon"
            />
          </OperatorGuideBadge>
          <div className="operator-guide-header_incident-detail">
            {incident.incidentTimestamp && (
              <div className="operation--h7">
                <span>{incident.incidentTimestamp}</span>
              </div>
            )}
            {incident.description && (
              <div className="operation--h4">
                <span>{incident.description}</span>
              </div>
            )}
            {incident.resourceMappingName && (
              <div className="operation--h7">
                <span>{incident.resourceMappingName}</span>
              </div>
            )}
          </div>
        </div>

        <div className="operator-guide-header__data-right">
          <div className="operator-guide-header__incident-identification operation--h5">
            <span>
              {translate("incident.incidentIdentification", {
                incidentId: getMinimalIncidentId(incident.id),
              })}
            </span>
          </div>
          <div className="operator-guide-header__incident-site operation--h6">
            <div>
              <span>
                <LocationOnIcon fontSize="medium" color="primary" />
              </span>
            </div>
            {incident.siteName && <span>{incident.siteName}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperatorGuideHeader;
