import React, { useEffect } from "react";
import { LocalizationNS, useFeatureFlag, useI18n } from "compass-commons";
import { Button } from "dms-lib";
import { SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG } from "../utils/Constants";
import { globalService } from "../services/GlobalService";

const useGenericErrorMessage = (): void => {
  const { t: translate } = useI18n();
  const { alertSubject } = globalService.stateService;
  const { enabled: showErrorMessagesFeatureFlag } = useFeatureFlag(
    appConfig,
    SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG
  );
  useEffect(() => {
    const handleReject = () => {
      if (!showErrorMessagesFeatureFlag) return;
      alertSubject.next({
        title: translate("genericErrorTitle", { ns: LocalizationNS.SHARED }),
        description: translate("genericErrorSubtitle", {
          ns: LocalizationNS.SHARED,
        }),
        action: (
          <Button
            size="small"
            color="primary"
            variant="text"
            onClick={() => window.location.reload()}
          >
            {translate("refresh", { ns: LocalizationNS.SHARED })}
          </Button>
        ),
      });
    };
    window.addEventListener("unhandledrejection", handleReject);
    return () => {
      window.removeEventListener("unhandledrejection", handleReject);
    };
  }, [showErrorMessagesFeatureFlag]);
};

export default useGenericErrorMessage;
