import React, { CSSProperties } from "react";

interface PdfIconProps {
  width?: string;
  height?: string;
  className?: string;
  style?: CSSProperties;
}
const PdfIcon = (props: PdfIconProps) => {
  const { width = "20px", height = "20px", className = "", style = {} } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      width={width}
      height={height}
      style={style}
    >
      <rect x="8" y="12" width="1" height="1" />
      <rect x="12" y="12" width="1" height="3" />
      <path d="M14,2H4V22H20V8ZM10,13a1,1,0,0,1-1,1H8v2H7V11H9a1,1,0,0,1,1,1Zm4,2a1,1,0,0,1-1,1H11V11h2a1,1,0,0,1,1,1Zm3-3H16v1h1v1H16v2H15V11h2Z" />
    </svg>
  );
};

export default PdfIcon;
