import React, { PropsWithChildren } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

interface CustomLinkProps {
  to: string;
  additionalPaths?: string[];
  className?: string;
  divWrapper?: boolean;
}

const matchMultiplePaths = (paths: string[]) => {
  return paths.some((path) => {
    const resolvedPath = useResolvedPath(path);
    return !!useMatch({
      path: resolvedPath.pathname,
      end: true,
    });
  });
};

/**
 * Custom Component to build Router links,
 * used for subnav or breadcrumb for example
 * @param to - url where to go
 * @param children
 * @param className - styles the wrapper
 * @param divWrapper - is used to define if the link is inside a list or not
 * @param additionalPaths
 * @returns JSX.Element
 */
const CustomLink = ({
  to,
  children,
  className,
  divWrapper = false,
  additionalPaths = [],
}: PropsWithChildren<CustomLinkProps>): JSX.Element => {
  const isActive = matchMultiplePaths([to, ...additionalPaths]);
  const Wrapper = divWrapper ? "div" : "li";
  return (
    <Wrapper className={`${className ?? ""}${isActive ? " active" : ""}`}>
      <Link
        to={to}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {children}
      </Link>
    </Wrapper>
  );
};

export default CustomLink;
