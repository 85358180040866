import React, { useEffect, useMemo, useState } from "react";
import "./mainPanelList.module.css";
import { ReportCart, ReportCartItem } from "compass-commons";
import _FlipMove from "react-flip-move";
import { BehaviorSubject } from "rxjs";
import ReportCartService from "../../../services/ReportCartService";
import { normalizeCart } from "../../../utils/Util";
import StateService from "../../../services/StateService";
import { useStateContext } from "../../../contexts/StateContext";
import MainPanelListItems from "./MainPanelListItems";

const FlipMove = _FlipMove as unknown as React.FC<_FlipMove.FlipMoveProps>;

interface MainPanelListProps {
  reportCartSubject: BehaviorSubject<ReportCart>;
}

const MainPanelList = (props: MainPanelListProps): JSX.Element => {
  const { reportCartSubject } = props;
  const [currentReportCart, setCurrentReportCart] = useState(
    reportCartSubject.getValue()
  );
  const stateService: StateService = useStateContext();
  const footerMessageSubject = stateService.finalizeReportCartMessage;
  const EMPTY_TABLE_TEXT = "Your report cart is empty.";
  const EMPTY_TABLE_TEXT_NEW = "This is a new reports cart, please add data.";
  const [emptyTableText, setEmptyTableText] = useState(EMPTY_TABLE_TEXT);

  useEffect(() => {
    const footerMessageSubscription = footerMessageSubject.subscribe(
      (value) => {
        if (value) {
          setEmptyTableText(EMPTY_TABLE_TEXT_NEW);
          setTimeout(() => {
            setEmptyTableText(EMPTY_TABLE_TEXT);
          }, 10000);
        }
      }
    );

    const subscription = reportCartSubject.subscribe((value) => {
      setCurrentReportCart(value);
    });

    return function cleanup() {
      subscription.unsubscribe();
      footerMessageSubscription.unsubscribe();
    };
  }, [reportCartSubject, currentReportCart]);

  const removeItemFromElementList = (
    elementList: ReportCartItem[],
    itemPosition: number
  ) => {
    if (elementList.length === 1) {
      return [];
    }
    elementList.splice(itemPosition, 1);
    return elementList;
  };

  const changeItemOrderUp = (
    elementList: ReportCartItem[],
    itemPosition: number
  ) => {
    const newList: ReportCartItem[] = [];
    for (let i = 0; i < elementList.length; i++) {
      if (i + 1 === itemPosition) {
        const targetItem = elementList[i + 1];
        const currentItem = elementList[i];
        currentItem.position += 1;
        targetItem.position -= 1;
        newList.push(targetItem);
        newList.push(currentItem);
        i += 1;
      } else {
        newList.push(elementList[i]);
      }
    }
    return newList;
  };

  const updateCart = (updatedCart: ReportCart) => {
    ReportCartService.updateCart(updatedCart)
      .then((cart) => {
        if (cart) {
          const cartLocalCopy: ReportCart = normalizeCart(
            { ...updatedCart } as ReportCart // Create shallow copy
          );
          reportCartSubject.next(cartLocalCopy);
        }
      })
      .catch(() => {
        console.error("Failed");
      });
  };

  const removeItemFromCart = (itemPosition) => {
    if (currentReportCart) {
      currentReportCart.elementList = removeItemFromElementList(
        currentReportCart.elementList,
        itemPosition
      );
      updateCart(currentReportCart);
    }
  };

  const changeItemOrder = (itemPosition) => {
    if (currentReportCart) {
      currentReportCart.elementList = changeItemOrderUp(
        currentReportCart.elementList,
        itemPosition
      );
      updateCart(currentReportCart);
    }
  };

  const MainPanelListItemsMemo = useMemo(
    () => (
      <MainPanelListItems
        items={currentReportCart?.elementList}
        changeItemOrderCallback={changeItemOrder}
        removeItemFromCartCallback={removeItemFromCart}
        emptyTableText={emptyTableText}
      />
    ),
    [currentReportCart]
  );

  return (
    <div id="main-panel-list" className="main-panel-list-div">
      {currentReportCart && (
        <FlipMove duration={200} easing="ease-out">
          {MainPanelListItemsMemo}
        </FlipMove>
      )}
    </div>
  );
};

export default MainPanelList;
