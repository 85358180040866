// eslint-disable-next-line no-shadow
export enum WebSocketStatus {
  NOT_CONNECTED,
  LISTENING,
  FAILED,
}

export interface WebSocketData {
  webSocket: WebSocket;
  state: WebSocketStatus;
}
