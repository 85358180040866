import React, { useEffect, useState } from "react";
import "./mainPanelHeader.module.css";
import { ReportCart } from "compass-commons";
import { BehaviorSubject } from "rxjs";
import {
  getLoggedUser,
  isSafeString,
  mapStringDateToTimezone,
} from "../../../utils/Util";
import ReportCartService from "../../../services/ReportCartService";
import CompassTextField from "../../commons/compassTextField/CompassTextField";

interface MainPanelHeaderProps {
  reportCartSubject: BehaviorSubject<ReportCart>;
}

const MainPanelHeader = (props: MainPanelHeaderProps): JSX.Element => {
  const { reportCartSubject } = props;
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [currentReportCart, setCurrentReportCart] = useState(
    reportCartSubject.getValue()
  );
  const [reportCartName, setReportCartName] = useState(
    currentReportCart?.name ? currentReportCart.name : "Report"
  );

  useEffect(() => {
    const reportCartSubscription = reportCartSubject.subscribe((cart) => {
      setCurrentReportCart(cart);

      if (cart) {
        setReportCartName(cart?.name ? cart.name : "Report");
      }
    });

    return function cleanup() {
      reportCartSubscription.unsubscribe();
    };
  }, [reportCartSubject]);

  const updateReportCartName = (textInput: string) => {
    if (currentReportCart && textInput?.length > 0 && isSafeString(textInput)) {
      const previousName = currentReportCart.name;
      currentReportCart.name = textInput;
      setReportCartName(currentReportCart.name);
      currentReportCart.requestedBy = getLoggedUser();
      ReportCartService.updateCart(currentReportCart)
        .then((cart) => {
          if (cart) {
            reportCartSubject.next(cart);
            setShowSuccess(true);
            setTimeout(() => {
              setShowSuccess(false);
            }, 4000);
          }
        })
        .catch(() => {
          setReportCartName(previousName);
          setShowFailure(true);
          setTimeout(() => {
            setShowFailure(false);
          }, 4000);
        });
    } else {
      setShowFailure(true);
      setTimeout(() => {
        setShowFailure(false);
      }, 4000);
    }
  };

  return (
    <div className="main-panel-header-main-div">
      <div className="main-panel-header-div">
        <CompassTextField
          updateCallback={updateReportCartName}
          showSuccess={showSuccess}
          showFailure={showFailure}
          fieldName="Report name"
          placeholder={reportCartName}
          customSuccess="Updated report successfully"
          customFailure="Failed to update report"
        />
        <div className="main-panel-header-meta">
          <div>
            <b>Request by </b>
            {currentReportCart?.requestedBy || getLoggedUser()}
          </div>
          <div>
            <b>Date </b>
            {mapStringDateToTimezone(currentReportCart?.creationDate)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPanelHeader;
