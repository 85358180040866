import React, { createRef, useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";
import GroupInfoSearchResult from "../../../../models/incidents/GroupInfoSearchResult";
import { mapGroupInfoData } from "../../../../helpers/mapGroupInfoData";
import "./resultsGroupInfo.module.css";
import SunburstGroupInfo from "./sunburstGroupInfo/SunburstGroupInfo";
import SunburstGroupTooltipInfo from "./sunburstGroupInfo/SunburstGroupInfoTooltip";
import StateService from "../../../../services/StateService";
import { useStateContext } from "../../../../contexts/StateContext";
import SunburstGroupLabels from "./sunburstGroupInfo/SunburstGroupLabels";

interface ResultsSunBurstProps {
  groupInfo: GroupInfoSearchResult;
  ringLimit?: number;
}

const ResultsGroupInfo = (props: ResultsSunBurstProps): JSX.Element => {
  const { groupInfo, ringLimit = 2 } = props; // ring size will always be this
  const fullData: GroupInfoSearchResult = mapGroupInfoData(groupInfo);
  const hoveredNodeSubject = new BehaviorSubject(null);
  const stateService: StateService = useStateContext();
  const {
    sunburstQuery,
    currentIncidentSearchResult,
    selectedGroupInfoSearchResult,
  } = stateService;
  const [selectedData, setSelectedData] = useState<GroupInfoSearchResult>(
    selectedGroupInfoSearchResult?.value
  );
  const containerRef = createRef<HTMLDivElement>();
  useEffect(() => {
    const subscription = selectedGroupInfoSearchResult.subscribe((data) => {
      setSelectedData(data);
    });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [selectedGroupInfoSearchResult]);

  function setHoveredNodeSubject(node) {
    setTimeout(() => {
      hoveredNodeSubject.next(node);
    }, 100);
  }

  function setSunburstQuery(node, zoomedData: GroupInfoSearchResult) {
    currentIncidentSearchResult.next(null);
    if (zoomedData) {
      selectedGroupInfoSearchResult.next(zoomedData);
      if (zoomedData?.parentId) {
        sunburstQuery.next(zoomedData.query);
      } else {
        sunburstQuery.next(fullData.query);
      }
    }
  }

  return (
    <div
      ref={containerRef}
      className="sunburst-main-div"
      data-cr="de-results-group-info"
    >
      <div className="sunburst-horizontal">
        <SunburstGroupInfo
          fullData={fullData}
          selectedData={selectedData}
          ringLimit={ringLimit}
          hoveredNodeCallback={setHoveredNodeSubject}
          selectedNodeCallback={setSunburstQuery}
        />
        <SunburstGroupLabels visibleData={selectedData || fullData} />
      </div>
      <SunburstGroupTooltipInfo
        nodeSubject={hoveredNodeSubject}
        containerRef={containerRef}
      />
    </div>
  );
};

export default ResultsGroupInfo;
