// General
import React, { useCallback } from "react";
import { useI18n } from "compass-commons";
import { useSelector } from "react-redux";
// Styles
import "./subsystemListPanel.module.css";
// Components
import CollapsiblePanel from "../../../commons/collapsiblePanel";
// Store
import { selectSubsystems, useStoreDispatch } from "../../../../store";
import { subsystemsActions } from "../../../../store/subsystems";
// Models
import { SubsystemLightConnections } from "../../../../models/subsystems/SubsystemLightDto";

interface SubsystemListPanelProps {
  filteredSubsystemsList: SubsystemLightConnections[];
  loadingSubsystemsList: boolean;
  selectedSubsystemId?: string;
}

const SubsystemListPanel = (props: SubsystemListPanelProps): JSX.Element => {
  const { t } = useI18n();
  const { filteredSubsystemsList, loadingSubsystemsList, selectedSubsystemId } =
    props;

  // Redux State Management
  const { searchTerm } = useSelector(selectSubsystems);

  const dispatch = useStoreDispatch();

  const searchCallback = useCallback(
    (filter: string) => dispatch(subsystemsActions.filterTextChanged(filter)),
    []
  );

  const selectSubsystemCallback = (subystem: SubsystemLightConnections) =>
    dispatch(subsystemsActions.subsystemSelected(subystem));

  return (
    <CollapsiblePanel
      dataCr="subsystem-list"
      searchBar={{
        dataCr: "subsystems-search-bar",
        searchCallback,
        searchTerm,
        placeholderLabel: t("subsystems.searchSubsystem"),
      }}
    >
      <div className="config-list-in" data-cr="subsystem-list-items">
        {(loadingSubsystemsList && <div>{t("loadingContent")}</div>) ||
          (filteredSubsystemsList?.length > 0 ? (
            filteredSubsystemsList?.map((subsystem) => (
              <button
                data-cr="subsystem-list-item"
                type="button"
                className={`config-subsystems-list__item${
                  subsystem.id === selectedSubsystemId ? " selected" : ""
                }`}
                key={subsystem.id}
                onClick={() => selectSubsystemCallback(subsystem)}
                title={subsystem.name}
              >
                <span>{subsystem.name}</span>
              </button>
            ))
          ) : (
            <div className="config-list-empty">
              {t("subsystems.noSubsystems")}
            </div>
          ))}
      </div>
    </CollapsiblePanel>
  );
};

export default SubsystemListPanel;
