import React, { useEffect } from "react";
import "./initialNode.module.css";
import { Handle, Position } from "reactflow";
import { useSelector } from "react-redux";
import { editionMode } from "../../../../../store/root";

interface InitialNodeProps {
  data?: string;
}

const InitialNode = (props: InitialNodeProps): JSX.Element => {
  const isEditionMode = useSelector(editionMode);
  const [className, setClassName] = React.useState("");

  useEffect(() => {
    let classNameToSet = "initial-node-block";
    if (isEditionMode) {
      classNameToSet += " initial-node-block-edition";
    }
    setClassName(classNameToSet);
  }, [isEditionMode]);
  const { data = "target" } = props;
  return (
    <div className={className}>
      <span className="initial-block-text">Start</span>
      <Handle
        id={data}
        type="source"
        position={Position.Bottom}
        className="node-block-handles"
        isConnectable
      />
    </div>
  );
};

export default InitialNode;
