import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import OgTaskTypeService from "../../../services/OgTaskTypeService";
import SearchBar from "../../commons/searchBar/SearchBar";
import "./ogTaskTypesPanel.module.css";
import TaskTypeWidget from "./taskTypeWidget/TaskTypeWidget";
// eslint-disable-next-line import/order
import { useI18n } from "compass-commons";

interface OgTaskTypesPanelProps {
  onMultiOptionRemove?: (blockId: string, optionId: string) => void;
  errorCallback?: (msg: string) => void;
}

const OgTaskTypesPanel = (props: OgTaskTypesPanelProps): JSX.Element => {
  const { onMultiOptionRemove, errorCallback } = props;
  const [taskTypesList, setTaskTypesList] = useState([]);
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useI18n();

  useEffect(() => {
    setIsLoading(true);
    OgTaskTypeService.getAllTaskTypes()
      .then((ogTaskTypeList) => {
        if (ogTaskTypeList) {
          setTaskTypesList(ogTaskTypeList);
        }
        setIsLoading(false);
      })
      .catch(() => {
        errorCallback?.(t("operatorGuide.failToLoadTaskTypes"));
        setIsLoading(false);
      });
  }, [errorCallback]);

  const updateFilter = (searchInput: string) => {
    setFilter(searchInput || "");
  };

  useEffect(() => {}, [filter]); // Force update when filter is changed

  const getTaskTypesWidgets = () => {
    const widgets: JSX.Element[] = [];

    taskTypesList?.forEach((taskType) => {
      if (
        !filter ||
        (filter !== "" &&
          taskType?.title?.toLowerCase().includes(filter.toLowerCase()))
      ) {
        widgets.push(
          <TaskTypeWidget
            taskType={taskType}
            onMultiOptionRemove={onMultiOptionRemove}
            key={taskType?.id}
          />
        );
      }
    });

    if (widgets.length === 0) {
      widgets.push(
        <div
          id="no-task-types"
          data-cr="no-task-types"
          key={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "15px",
          }}
        >
          <i>{t("operatorGuide.taskTypesNotFound")}</i>
        </div>
      );
    }

    return widgets;
  };

  return (
    <div className="og-task-types-main" data-cr="task-types-panel">
      <b style={{ fontSize: "15px" }}>{t("operatorGuide.taskTypes")}:</b>
      <div className="og-task-types-search-bar__wrapper">
        <SearchBar
          searchCallback={updateFilter}
          dataCr="task-types-search"
          placeholder={t("operatorGuide.search")}
        />
      </div>
      {isLoading ? (
        <div id="task-types-loading" key={2}>
          <Skeleton height="30px" />
          <Skeleton height="30px" />
          <Skeleton height="30px" />
        </div>
      ) : (
        <div className="og-task-types-list">{getTaskTypesWidgets()}</div>
      )}
    </div>
  );
};

export default OgTaskTypesPanel;
