import React, { useState } from "react";
import { BehaviorSubject } from "rxjs";
import { useI18n } from "compass-commons";
import { useSelector } from "react-redux";
import { OgBlockTypeDto } from "../../../../../../models/ogTaskTypes/OgBlockTypeDto";
import { OgTemplateDto } from "../../../../../../models/ogTemplate/OgTemplateDto";
import OgTemplateService from "../../../../../../services/OgTemplateService";
import CompassTextField from "../../../../../commons/compassTextField/CompassTextField";
import { operatorGuideActions } from "../../../../../../store/operatorGuide";
import { selectOperatorGuide, useStoreDispatch } from "../../../../../../store";

interface ContextTagNodeBlockProps {
  block?: OgBlockTypeDto;
  icon?: any;
  ogTemplateSubject?: BehaviorSubject<OgTemplateDto>;
  errorCallback?: (msg: string) => void;
}

const ContextTagNodeBlock = (props: ContextTagNodeBlockProps): JSX.Element => {
  const { block, icon, ogTemplateSubject, errorCallback } = props;
  const [editMode, setEditMode] = useState(false);
  const { t: translate } = useI18n();
  const { templateStub } = useSelector(selectOperatorGuide);
  const dispatch = useStoreDispatch();

  const modifyOgTemplate = (modified: boolean) => {
    if (ogTemplateSubject) {
      const ogTemplate = ogTemplateSubject.value;
      ogTemplate.modified = modified;

      ogTemplateSubject.next(ogTemplate);
    }
  };

  const updateTag = async (tagsString) => {
    let tags = [];
    if (tagsString.trim().length > 0) {
      tags = tagsString.split(",");
    }
    try {
      const result = await OgTemplateService.validateResourceTagList(tags);
      const validationMap = result.tagIsValidMap as Map<string, boolean>;
      let invalidTags = [];
      tags?.forEach((tag) => {
        if (tag in validationMap && !validationMap[tag]) {
          invalidTags = [...invalidTags, tag];
        }
      });
      if (invalidTags.length === 0) {
        block.tags = tags;
        modifyOgTemplate(true);
      } else {
        errorCallback?.(
          `${translate("operatorGuide.tagNotValid")}: '${invalidTags.join(
            "','"
          )}'`
        );
        modifyOgTemplate(false);
      }
    } catch (error) {
      errorCallback?.(translate("operatorGuide.failToValidateTag"));
      modifyOgTemplate(false);
      // eslint-disable-next-line
      console.error(error);
    }
    setEditMode(false);
    dispatch(operatorGuideActions.removeTemplateStub(block?.id));
    dispatch(operatorGuideActions.setIsEditingTaskText(false));
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className="task-node-block" data-cr="task-node-context-tag">
      {icon}
      <b>{translate("operatorGuide.tag")}: </b>
      <div style={{ width: "100%" }}>
        {editMode ? (
          <div
            onMouseLeave={() => {
              dispatch(operatorGuideActions.setIsEditingTaskText(false));
            }}
            onMouseEnter={() => {
              dispatch(operatorGuideActions.setIsEditingTaskText(true));
            }}
          >
            <CompassTextField
              updateCallback={(e) => updateTag(e)}
              showSuccess={false}
              showFailure={false}
              editButtonHidden
              textFieldId={block.id}
              fieldIdInEdition={editMode ? block.id : null}
              maxTextLength={255}
              backButton={false}
              defaultValue={
                templateStub.indexOf(block.id) >= 0
                  ? null
                  : (block.tags?.length > 0 && block.tags.toString()) || null
              }
              safeString={false}
            />
          </div>
        ) : (
          <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              style={{ width: "100%" }}
              onDoubleClick={() => {
                if (!editMode) {
                  setEditMode(true);
                  dispatch(operatorGuideActions.setIsEditingTaskText(true));
                }
              }}
            >
              <span className="wrap-text">
                {(block.tags?.length > 0 && block.tags.toString()) ||
                  translate("operatorGuide.tagTriggerResource")}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContextTagNodeBlock;
