import React, { useEffect, useRef, useState } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import { Incident, useI18n } from "compass-commons";
import { AutoComplete, TextField } from "dms-lib";
import ResourceMappingService from "../../../../../../services/ResourceMappingService";
import DatabaseSearchResponseDTO from "../../../../../../model/databaseSearch/DatabaseSearchResponseDTO";
import { CustomOption } from "./dropdownOptions/CustomOption";
import { mapSearchResultDtoToInternalSearchResult } from "./Util";
import DatabaseSearchResult from "../../../../../../model/databaseSearch/internal/DatabaseSearchResult";
import CreateNotFoundOption from "./DatabaseSearchResultNotFound";
import { TaskStepState } from "../../../../../../model/OG/TaskStepState";
import { SearchImageBase } from "../../../../../../model/databaseSearch/internal/SearchImageBase";
import SearchBarStartAdornment from "./SearchBarStartAdornment";
import CreateMaxResultsOption from "./DatabaseSearchResultMaxResults";
import OptionType from "../../../../../../model/databaseSearch/internal/OptionType";

const { SEARCH_TASK_PAGE_SIZE, SEARCH_MAX_RESULTS } = appConfig;

interface DatabaseSearchDropdownBlockProps {
  dataCr?: string;
  placeholder?: string;
  tags: string[];
  taskState: TaskStepState;
  finalSelectedOption: DatabaseSearchResult;
  onChangeCallback: (opt: DatabaseSearchResult) => void;
  incident: Incident;
}

const DatabaseSearchDropdownBlock = (
  props: DatabaseSearchDropdownBlockProps
): React.JSX.Element => {
  const {
    dataCr = "search-bar",
    placeholder = "Search...",
    tags,
    taskState,
    finalSelectedOption,
    onChangeCallback,
    incident,
  } = props;

  const [options, setOptions] = useState<DatabaseSearchResult[]>([]);
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [searching, setSearching] = useState(
    taskState === TaskStepState.COMPLETED
  ); // If the task is completed we want 'search' to be disabled
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] =
    useState<DatabaseSearchResult | null>(null);

  const { t: translate } = useI18n();
  const fetchMoreResults = useRef(true);
  const internalPage = useRef(1);

  const handleDatabaseSearchResponse = (
    response: DatabaseSearchResponseDTO,
    newSearch: boolean
  ) => {
    const { subsystemsResults, totalResults } = response;

    if (totalResults > parseInt(SEARCH_MAX_RESULTS)) {
      setOptions([
        CreateMaxResultsOption(
          translate("incident.operatorGuide.dbSearchTask.maxResults")
        ),
      ]);
      return;
    }

    // Map the DTO results to the internal format
    const newOptions: DatabaseSearchResult[] = subsystemsResults.reduce(
      (accumulator, subsystemResult) => {
        accumulator.push(
          ...subsystemResult.results.map((result) => {
            return mapSearchResultDtoToInternalSearchResult(
              result,
              subsystemResult.subsystemId,
              subsystemResult.subsystemName
            );
          })
        );

        return accumulator;
      },
      []
    );

    if (newSearch) {
      fetchMoreResults.current = response.totalResults > newOptions.length;

      // Create a 'not found' option and add it to the beginning of the list
      newOptions.unshift(
        CreateNotFoundOption(
          translate("incident.operatorGuide.dbSearchTask.resultNotFound")
        )
      );

      setOptions(newOptions);
    } else {
      fetchMoreResults.current =
        response.totalResults > [...options, ...newOptions].length - 1; // subtract one due to result not found option
      setOptions((prevOptions) => [...prevOptions, ...newOptions]);
    }
  };

  const fetchData = async (newSearch = true, pageIndex = 1) => {
    if (!inputValue) {
      return;
    }

    setSearching(true);
    if (newSearch) {
      // Open the dropdown to show the loading message and clear the options
      setOpenDropdown(true);
      setOptions([]);

      internalPage.current = 1;
    }

    ResourceMappingService.databaseSearch(
      tags,
      inputValue,
      pageIndex,
      parseInt(SEARCH_TASK_PAGE_SIZE),
      incident.triggerDeviceId
    )
      .then((response: DatabaseSearchResponseDTO) => {
        handleDatabaseSearchResponse(response, newSearch);
      })
      .finally(() => setSearching(false));
  };

  // When user types something
  useEffect(() => {
    // If the user has an option selected, we don't want to search
    if (selectedOption) {
      return () => {};
    }

    let searchSetTimeout;

    // If the user types something, we want to wait a bit before searching (Debounce)
    if (inputValue) {
      searchSetTimeout = setTimeout(fetchData, 750);
    } else {
      // If the user deletes the input, we want to clear the options and close the dropdown
      setOptions([]);
      setOpenDropdown(false);
    }

    return () => {
      if (searchSetTimeout) clearTimeout(searchSetTimeout);
    };
  }, [inputValue]);

  const handleInputChange = (event?: React.SyntheticEvent<Element, Event>) => {
    if (event.type === "change" && event.target) {
      const newInputValue = (event.target as any)?.value;

      // If the input value is manually changed by the user, reset the selected value (if there is one)
      if (selectedOption && newInputValue) {
        setSelectedOption(null);
        onChangeCallback(null);
      }
      setInputValue(newInputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      fetchData();
    }
  };

  const handleSelectOption = (value) => {
    setSelectedOption(value);
    onChangeCallback(value);
    setOpenDropdown(false);
  };

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        data-cr="dropdown-search-bar"
        InputProps={{
          ...params.InputProps,
          endAdornment: <SearchRoundedIcon />,
          className: "og-search-task-dropdown__text-input",
          startAdornment: (
            <SearchBarStartAdornment selectedOption={selectedOption} />
          ),
        }}
        placeholder={placeholder}
      />
    );
  };

  const onImageStateChange = (
    image: SearchImageBase,
    optionId: string,
    imageId: string
  ): void => {
    const imageToUpdate: SearchImageBase = options
      .find((opt) => opt.id === optionId)
      .images.find((img) => img.imageId === imageId);

    imageToUpdate.fileType = image.fileType;
    imageToUpdate.imageData = image.imageData;
    imageToUpdate.state = image.state;
  };

  const infiniteScrollObj = {
    pageLength: parseInt(SEARCH_TASK_PAGE_SIZE),
    hasMore: fetchMoreResults.current,
    isLoading: searching,
    fetchNext: () => {
      // This callback gives a pageIndex but an internal page counter is necessary because as this is not a common pagination (has multiple sources),
      // the dms-lib may skip pages. this happens because the page size may not be the true maximum of results per page but per subsystem/integration
      // eg., if page size = 20 but the db search request is made to two integrations (with more than 20 results combined), the first page request will have more than 25 results
      internalPage.current += 1;
      fetchData(false, internalPage.current);
    },
  };

  if (!incident.triggerDeviceId && (!tags || tags.length === 0)) {
    return (
      <i className="info-text">
        <br />
        {translate("incident.operatorGuide.dbSearchTask.noResourcesConfigured")}
      </i>
    );
  }

  return taskState === TaskStepState.COMPLETED ? (
    <CustomOption option={finalSelectedOption} displayingSelectedOption />
  ) : (
    <AutoComplete
      onChangeCallback={handleSelectOption} // The onChange handler runs when the user selects an option from the dropdown
      autoComplete={false}
      data-cr={dataCr}
      loading={searching}
      open={openDropdown}
      disableFilterOptions
      getOptionDisabled={(option) => option.type === OptionType.MAX_RESULTS}
      getOptionLabel={(
        option // As we are using freeSolo, we must accept both: the type of the options and a string
      ) => (typeof option === "string" ? option : option.textToDisplay)}
      renderOption={(optionProps, option) => (
        <li {...optionProps}>
          <CustomOption
            option={option}
            displayingSelectedOption={false}
            onImageStateChange={onImageStateChange}
          />
        </li>
      )}
      disablePortal
      freeSolo // Disables the popup 'No options' message
      forcePopupIcon={false}
      id="combo-box-demo"
      options={options}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="og-search-task-dropdown"
      value={inputValue}
      clearIcon={false}
      disabled={searching}
      renderInput={(params) => renderInput(params)}
      infiniteScroll={infiniteScrollObj}
    />
  );
};

export default DatabaseSearchDropdownBlock;
