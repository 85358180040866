import React from "react";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import DatabaseSearchResult from "../../../../../../../model/databaseSearch/internal/DatabaseSearchResult";
import { OptionImages } from "./OptionImages";
import OptionInformation from "./OptionInformation";
import { SearchImageBase } from "../../../../../../../model/databaseSearch/internal/SearchImageBase";

interface CustomOptionProps {
  option: DatabaseSearchResult;
  displayingSelectedOption: boolean;
  onImageStateChange?: (
    image: SearchImageBase,
    optionId: string,
    imageId: string
  ) => void;
}

/**
 * Component that renders a custom option for the Autocomplete component used in the Database Search task.
 * @param props - The props of the component
 * @returns custom option
 */
const CustomOption = (props: CustomOptionProps): React.JSX.Element => {
  const { option, displayingSelectedOption, onImageStateChange = null } = props;
  const divClassName = "og-search-task-dropdown-option";

  return (
    <div
      key={option.id}
      className={
        displayingSelectedOption
          ? `${divClassName} og-search-selected-option`
          : divClassName
      }
    >
      <Box display="flex">
        <OptionImages
          optionId={option.id}
          optionType={option.type}
          images={option.images}
          subsystemId={option.subsystemId}
          displayingSelectedOption={!displayingSelectedOption}
          onImageStateChange={onImageStateChange}
        />
        <OptionInformation option={option} />
      </Box>

      {!displayingSelectedOption ? (
        <Divider className="og-search-task-dropdown-divider" />
      ) : (
        <></>
      )}
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { CustomOption };
