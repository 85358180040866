import React, { PropsWithChildren } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useTabActionsContext } from "../../../contexts/TabActionsContext";

interface CustomLinkProps {
  to: string;
  className?: string;
  divWrapper?: boolean;
}

/**
 * Custom Component to build Router links,
 * used for subnav or breadcrumb for example
 * @param to - url where to go
 * @param children
 * @param className - styles the wrapper
 * @param divWrapper - is used to define if the link is inside a list or not
 * @returns JSX.Element
 */
const CustomLink = ({
  to,
  children = null,
  className,
  divWrapper = false,
}: PropsWithChildren<CustomLinkProps>): JSX.Element => {
  const { resetHandler } = useTabActionsContext();

  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  const Wrapper = divWrapper ? "div" : "li";
  return (
    <Wrapper className={`${className ?? ""}${isActive ? " active" : ""}`}>
      <Link
        to={to}
        onClick={(event) => {
          event.stopPropagation();
          if (!isActive) resetHandler(true);
        }}
      >
        {children}
      </Link>
    </Wrapper>
  );
};

export default CustomLink;
