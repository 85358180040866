import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import MuiDialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "dms-lib";

import { useI18n } from "compass-commons";
import { object, string } from "zod";
import FormAiAssistedOg, {
  defaultValues,
  FormValues,
} from "./FormAiAssistedOg";
import useTabActions from "../../../hooks/useTabActions";
import OgTemplateService from "../../../services/OgTemplateService";
import { ConfigEditionModes } from "../../../store/root";
import { selectRoot, selectSites } from "../../../store";
import {
  OgTemplateAssistedDto,
  OgTemplateDto,
} from "../../../models/ogTemplate/OgTemplateDto";

const Dialog = styled(MuiDialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    margin: theme.spacing(1, 0),
    width: 444,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(0, 3, 3, 3),
  },
  "& .MuiPaper-root": {
    borderRadius: 8,
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  setLoadTemplates: (loading: boolean) => void;
  setCreatedOgTemplate: (templateId: string) => void;
  ogTemplates: OgTemplateDto[];
  notificationCallback?: (msg: string, severity?: string) => void;
};

const DialogAiAssistedOg = ({
  onClose,
  open,
  setLoadTemplates,
  setCreatedOgTemplate,
  ogTemplates,
  notificationCallback,
}: Props): JSX.Element => {
  const { t } = useI18n();
  const { configEditMode } = useSelector(selectRoot);
  const { selectedSiteId } = useSelector(selectSites);
  const { setReadOnlyMode } = useTabActions();

  const [loading, setLoading] = useState(false);

  const methods = useForm<FormValues>({
    resolver: zodResolver(
      object({
        message: string().min(5, t("operatorGuide.ogPromptShort")),
      }).required()
    ),
    defaultValues,
  });

  const handleClose = () => {
    if (loading) return;
    setReadOnlyMode();
    onClose();
    methods.reset();
  };

  const handleTeardown = () => {
    setLoading(false);
    handleClose();
  };

  const handleCreate = methods.handleSubmit(async (data) => {
    setLoading(true);

    try {
      const template = { ...data } as OgTemplateAssistedDto;
      template.operatorGuideSiteId = selectedSiteId;
      template.operatorGuideName = `OG template AI ${ogTemplates.length + 1}`;

      await OgTemplateService.createTemplateAssisted(template).then(
        (response) => {
          setCreatedOgTemplate(response.id);
          setLoadTemplates(true);
          handleTeardown();
          notificationCallback(t("operatorGuide.successCreatedOg"), "success");
        }
      );
    } catch (error) {
      handleTeardown();
      notificationCallback(t("operatorGuide.failCreateOg"));
    }
  });

  const handleCancel = () => {
    setReadOnlyMode();
  };

  const handleSave = () => {
    setReadOnlyMode();
  };

  useEffect(() => {
    switch (configEditMode) {
      case ConfigEditionModes.SAVE:
        handleSave();
        break;
      case ConfigEditionModes.CANCEL:
        handleCancel();
        break;
      default:
        break;
    }
  }, [configEditMode]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Stack spacing={2} direction="row" alignItems="center">
          <AutoAwesomeRoundedIcon style={{ fill: "rgba(0,0,0,0.6)" }} />
          <Typography variant="h4" fontWeight={600}>
            {t("operatorGuide.ogWizard")}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <FormAiAssistedOg loading={loading} />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
          disabled={loading}
        >
          {t("cancel", { ns: "Shared" })}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          dataCr="generate-ai-template"
          onClick={handleCreate}
          disabled={loading}
        >
          {t("operatorGuide.createOg")}
          {loading && (
            <CircularProgress
              size={20}
              color="inherit"
              style={{ marginLeft: 8 }}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAiAssistedOg;
