import React from "react";
import { ReportCartItem } from "compass-commons";
import MainPanelListItem from "./mainPanelListItem/MainPanelListItem";

interface MainPanelListItemsProps {
  items: ReportCartItem[];
  changeItemOrderCallback: (itemPosition: number) => void;
  removeItemFromCartCallback: (itemPosition: number) => void;
  emptyTableText: string;
}

const MainPanelListItems = ({
  items,
  changeItemOrderCallback,
  removeItemFromCartCallback,
  emptyTableText,
}: MainPanelListItemsProps): JSX.Element => {
  return (
    <>
      {items.map((item) => (
        <div
          key={`${item.id}__${item.position}`}
          data-cr={`${item.id}__${item.position}`}
        >
          {item?.type ? (
            <MainPanelListItem
              cartItem={item}
              changeItemOrderCallback={changeItemOrderCallback}
              deleteItemOrderCallback={removeItemFromCartCallback}
            />
          ) : (
            emptyTableText
          )}
        </div>
      ))}
    </>
  );
};

export default MainPanelListItems;
