// General
import React from "react";
// Material UI
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

/* eslint-disable @typescript-eslint/no-explicit-any */

interface CompassDialogProps {
  dialogState: boolean;
  onClose: (event?: any) => void;
  dialogContent?: JSX.Element;
  dialogActions?: JSX.Element;
}

const CompassDialog = ({
  dialogState,
  onClose,
  dialogContent,
  dialogActions,
}: CompassDialogProps): JSX.Element => {
  return (
    <Dialog data-cr="delete-modal" open={dialogState} onClose={onClose}>
      {dialogContent ? <DialogContent>{dialogContent}</DialogContent> : null}
      {dialogActions ? <DialogActions>{dialogActions}</DialogActions> : null}
    </Dialog>
  );
};

export default CompassDialog;
