import { Button } from "dms-lib";
import "./buttons.module.css";
import React, { ReactElement } from "react";

interface ActionButtonProps {
  accessor?: ReactElement;
  accessorPosition?: "left" | "right";
  className?: string;
  disabled?: boolean;
  onClick: () => void;
  icon: ReactElement;
}

const SideActions = ({
  accessor,
  accessorPosition,
  className = "",
  disabled = false,
  onClick,
  icon,
}: ActionButtonProps): JSX.Element => {
  return (
    <div className={`action-button ${className}`}>
      {accessorPosition === "left" && accessor}
      <Button
        variant="contained"
        color="primary"
        icon
        onClick={onClick}
        disabled={disabled}
      >
        {icon}
      </Button>
      {accessorPosition === "right" && accessor}
    </div>
  );
};

export default SideActions;
