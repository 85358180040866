import React, { PropsWithChildren } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getNewId } from "compass-commons";
import { CircularProgress } from "@mui/material";
import "./compassInfiniteList.module.css";

interface CompassInfiniteListProps {
  data?: any[];
  emptyMessage?: string;
  endMessage?: string;
  dataElement?: (element) => JSX.Element;
  isLastPage?: boolean;
  fetchNextData?: () => void;
  dataCr?: string;
  scrollableTarget?: string;
}

/**
 * Compass component for a list object with infinite scroll
 * @param data
 * @param emptyMessage
 * @param endMessage
 * @param isLastPage
 * @param fetchNextData
 * @param dataElement
 * @param dataCr
 * @param scrollableTarget
 * @returns JSX.Element
 */
const CompassInfiniteList = ({
  data = [],
  emptyMessage = "No data found",
  endMessage = "No more data!",
  isLastPage = false,
  fetchNextData,
  dataElement = (item) => <div key={getNewId()}>{item}</div>,
  dataCr = "compass-infinite-list",
  scrollableTarget,
}: PropsWithChildren<CompassInfiniteListProps>): JSX.Element => {
  return data?.length > 0 ? (
    <div
      id="compass-infinite-scroll-div"
      className="compass-infinite-scroll-div"
      data-cr={dataCr}
    >
      <InfiniteScroll
        data-cr={`${dataCr}-scroll`}
        className="compass-infinite-scroll"
        dataLength={data?.length}
        next={() => fetchNextData?.()}
        hasMore={!isLastPage}
        loader={
          <div className="compass-infinite-scroll__loading">
            <CircularProgress color="inherit" size={12} />
          </div>
        }
        scrollableTarget={scrollableTarget || "compass-infinite-scroll-div"}
        endMessage={<span className="config-list-empty">{endMessage}</span>}
      >
        <div data-cr={`${dataCr}-el`}>
          {data.map((item) => dataElement(item))}
        </div>
      </InfiniteScroll>
    </div>
  ) : (
    <span className="config-list-empty" data-cr={`${dataCr}-empty`}>
      {emptyMessage}
    </span>
  );
};

export default CompassInfiniteList;
