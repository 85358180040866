import React, { useState } from "react";
import "./compassTextField.module.css";
import Input from "@msi/cobalt-react/input";
import { Button } from "dms-lib";
import {
  ArrowBackRounded,
  CheckRounded,
  EditRounded,
} from "@mui/icons-material";

interface CompassTextFieldProps {
  customSuccess?: string;
  customFailure?: string;
  updateCallback: any;
  placeholder?: string;
  fieldName: string;
  showSuccess: boolean;
  showFailure: boolean;
  rule?: RegExp;
  ruleError?: string;
}

const CompassTextField = (props: CompassTextFieldProps): JSX.Element => {
  const {
    customSuccess = "Updated Report Successfully",
    customFailure = "Failed to update Report",
    showSuccess,
    showFailure,
    updateCallback,
    placeholder = "Insert value",
    fieldName,
    rule,
    ruleError,
  } = props;
  const [editing, setEditing] = useState(false);
  const [textInput, setTextInput] = useState<string>("");
  const [invalidText, setInvalidText] = useState(false);
  const MAX_INPUT_SIZE = 30;

  const editingMode = () => {
    if (editing) {
      updateCallback(textInput);
    }
    setEditing(!editing);
    setTextInput("");
    setInvalidText(false);
  };

  const assertInputChange = (input: string) => {
    if (rule) {
      if (rule.test(input)) {
        setInvalidText(false);
        setTextInput(input);
      } else {
        setInvalidText(true);
      }
      return;
    }

    if (input.length > MAX_INPUT_SIZE) {
      setInvalidText(true);
    } else {
      setInvalidText(false);
      setTextInput(input);
    }
  };

  const DefaultError: React.FC = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ color: "red" }}>Invalid format.</span>
        <span style={{ fontSize: "small", color: "grey" }}>
          Maximum length is 30 characters
        </span>
      </div>
    );
  };

  const CustomError: React.FC = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ color: "red" }}>Invalid format.</span>
        <span style={{ fontSize: "small", color: "grey" }}>{ruleError}</span>
      </div>
    );
  };

  function loadError() {
    return !ruleError ? <DefaultError /> : <CustomError />;
  }

  return (
    <div className="compass-text-field-div">
      <div className="compass-text-field">
        {editing ? (
          <>
            <Button
              variant="contained"
              color="inherit"
              aria-label="backButton"
              icon
              onClick={() => {
                setEditing(false);
                setInvalidText(false);
              }}
            >
              <ArrowBackRounded color="primary" />
            </Button>
            <div className="reports-compass-text-field-input">
              <Input
                className="compass-rounded-corner"
                placeholder={placeholder}
                disabled={!editing}
                autoFocus
                onChange={(e) => assertInputChange(e?.target?.value as string)}
                invalid={invalidText}
              />
              {invalidText && loadError()}
            </div>
            <Button
              variant="contained"
              color="inherit"
              icon
              aria-label="editButton"
              onClick={editingMode}
              disabled={invalidText}
            >
              <CheckRounded color="primary" />
            </Button>
          </>
        ) : (
          <>
            <div className="compass-text-field-placeholder">
              <b>{fieldName}: </b>
              {placeholder}
            </div>
            <Button
              variant="contained"
              color="inherit"
              aria-label="editButton"
              onClick={editingMode}
              icon
            >
              <EditRounded color="primary" />
            </Button>
          </>
        )}
      </div>

      {showSuccess && (
        <div style={{ color: "green", width: "max-content" }}>
          {customSuccess}
        </div>
      )}
      {showFailure && <div style={{ color: "red" }}>{customFailure}</div>}
    </div>
  );
};

export default CompassTextField;
