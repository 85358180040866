// General
import React, { useEffect, useState } from "react";
import { useI18n } from "compass-commons";
// Styles
import "./clearIncidentDialogActions.module.css";
import { Button } from "dms-lib";

/* eslint-disable @typescript-eslint/no-explicit-any */

interface ClearIncidentDialogActionsProps {
  onCancel: (event?: any) => void;
  onSuccess: (event?: any) => void;
  approvalConditionsMet?: boolean;
}

const ClearIncidentDialogActions = ({
  onCancel,
  onSuccess,
  approvalConditionsMet = false,
}: ClearIncidentDialogActionsProps): JSX.Element => {
  const { t: translate } = useI18n();
  const [displayWarning, setDisplayWarning] = useState(false);
  const onClickHandler = () => {
    setDisplayWarning(!approvalConditionsMet);
    if (approvalConditionsMet) {
      onSuccess();
    }
  };

  useEffect(() => {
    if (displayWarning) {
      setTimeout(() => {
        setDisplayWarning(!displayWarning);
      }, 4000);
    }
  }, [displayWarning]);

  return (
    <div className="clear-incident-dialog-actions__wrapper">
      {displayWarning && (
        <div className="clear-incident-dialog-actions-warning">
          <span>
            {translate("supervisor.clearPopup.warning.withoutMessage")}
          </span>
        </div>
      )}
      <div className="clear-incident-dialog-actions">
        <Button
          dataCr="cancel-clear-incident-delete"
          variant="contained"
          color="primary"
          onClick={onCancel}
        >
          {translate("supervisor.clearPopup.noButton")}
        </Button>
        <Button
          dataCr="confirm-clear-incident-delete"
          variant="outlined"
          color="primary"
          onClick={() => onClickHandler()}
        >
          {translate("supervisor.clearPopup.yesButton")}
        </Button>
      </div>
    </div>
  );
};

export default ClearIncidentDialogActions;
