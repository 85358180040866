import React, { useState } from "react";
import { Sunburst } from "react-vis";
import GroupInfoSearchResult from "../../../../../models/incidents/GroupInfoSearchResult";
import useWindowDimensions from "../../../../../helpers/useWindowDimensions";

interface SunburstGroupInfoProps {
  fullData: GroupInfoSearchResult;
  ringLimit: number;
  hoveredNodeCallback;
  selectedNodeCallback;
  selectedData?: GroupInfoSearchResult;
}

const getDataWithLimit = (
  data: GroupInfoSearchResult,
  limit
): GroupInfoSearchResult => {
  const auxData = data;
  if (limit <= 0) {
    auxData.size = auxData.count + 1;
    if (auxData?.children.length > 0) {
      auxData.children = [];
    }
  } else {
    auxData?.children.map((child) => getDataWithLimit(child, limit - 1));
  }
  return auxData;
};

const SunburstGroupInfo = (props: SunburstGroupInfoProps): JSX.Element => {
  const {
    fullData,
    selectedData,
    ringLimit,
    hoveredNodeCallback,
    selectedNodeCallback,
  } = props;
  const dimensions = useWindowDimensions();
  const [visibleData, setVisibleData] = useState(
    selectedData ||
      getDataWithLimit(GroupInfoSearchResult.copy(fullData), ringLimit)
  );

  const setVisibleDataWithLimit = (data: GroupInfoSearchResult) => {
    const limitedData = getDataWithLimit(data, ringLimit);
    setVisibleData(limitedData);
    return limitedData;
  };

  const getNodeFromFullData = (node, id: string): GroupInfoSearchResult => {
    if (node?.id === id) {
      return node;
    }
    if (node?.children && node.children.length > 0) {
      for (const element of node.children) {
        const child: GroupInfoSearchResult = element;
        const foundNode = getNodeFromFullData(child, id);
        if (foundNode != null) {
          return foundNode;
        }
      }
      return null;
    }
    return null;
  };

  const performZoom = (node) => {
    let limitedData = null;
    if (node?.id != null) {
      let data;
      if (node.radius0 === 0 && node?.parentId != null) {
        data = getNodeFromFullData(fullData, node.parentId);
      } else {
        data = getNodeFromFullData(fullData, node.id);
      }
      if (data != null) {
        limitedData = setVisibleDataWithLimit(GroupInfoSearchResult.copy(data));
      }
    }
    return limitedData;
  };

  return (
    <>
      {visibleData && (
        <div className="results-sunburst-div compass-fade-in-smooth">
          <Sunburst
            animation
            colorType="literal"
            data={visibleData}
            height={dimensions.height * 0.55}
            width={dimensions.width * 0.35}
            style={{ stroke: "#fff" }}
            getSize={(d) => d.size}
            getColor={(d) => d.color}
            padAngle={() => 0.001}
            onValueMouseOver={(node) => {
              // IF NEEDED, PASS PATH THROUGH THIS CALLBACK
              hoveredNodeCallback(node);
            }}
            onValueMouseOut={() => {
              hoveredNodeCallback(null);
            }}
            onValueClick={(node) => {
              const limitedData = performZoom(node);
              selectedNodeCallback(node, limitedData);
            }}
          />
        </div>
      )}
    </>
  );
};

export default SunburstGroupInfo;
