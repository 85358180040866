/**
 * Og Template Service holds operations which will be executed by calling Backend APi
 */
import { HttpClient } from "compass-shared-services";
import {
  OgTemplateAssistedDto,
  OgTemplateDto,
} from "../models/ogTemplate/OgTemplateDto";
import { OgTemplateListDTO } from "../models/ogTemplate/OgTemplateListDTO";

const { OPERATOR_GUIDE_TEMPLATE_MANAGER_PATH, RESOURCE_MAPPING_MANAGER_PATH } =
  appConfig;
const URL_PATHS = {
  OG_TEMPLATES: `${OPERATOR_GUIDE_TEMPLATE_MANAGER_PATH}/operator-guide-template/templates`,
  TAG_SERVICE: `${RESOURCE_MAPPING_MANAGER_PATH}/resource-mapping/validate-tags`,
  OG_TEMPLATES_ASSISTED: `${OPERATOR_GUIDE_TEMPLATE_MANAGER_PATH}/ai/prompt`,
};

const httpClient = new HttpClient(appConfig);

export default class OgTemplateService {
  static async getAllOgTemplates(
    siteId: string,
    includeAncestors = false
  ): Promise<OgTemplateDto[]> {
    return httpClient
      .get<OgTemplateListDTO>({
        url: URL_PATHS.OG_TEMPLATES,
        config: {
          params: {
            siteId,
            ...(includeAncestors
              ? { includeSiteAncestors: includeAncestors }
              : {}),
          },
        },
      })
      .then((response) => {
        const templates: OgTemplateDto[] = new Array<OgTemplateDto>();
        if (
          response?.operatorGuideTemplateList &&
          response.operatorGuideTemplateList.length > 0
        ) {
          response.operatorGuideTemplateList.forEach((o) => templates.push(o));
        }
        return templates;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async createTemplate(template: OgTemplateDto): Promise<OgTemplateDto> {
    return httpClient
      .post<OgTemplateDto>({ url: URL_PATHS.OG_TEMPLATES, payload: template })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async createTemplateAssisted(
    template: OgTemplateAssistedDto
  ): Promise<OgTemplateDto> {
    return httpClient
      .post<OgTemplateDto>({
        url: URL_PATHS.OG_TEMPLATES_ASSISTED,
        payload: template,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async updateTemplate(template: OgTemplateDto): Promise<OgTemplateDto> {
    const urlPath = `${URL_PATHS.OG_TEMPLATES}/${template.id}`;
    return httpClient
      .put<OgTemplateDto>({ url: urlPath, payload: template })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async deleteTemplateById(id: string): Promise<OgTemplateDto> {
    const urlPath = `${URL_PATHS.OG_TEMPLATES}/${id}`;
    return httpClient
      .delete<OgTemplateDto>({ url: urlPath })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getTemplateById(id: string): Promise<OgTemplateDto> {
    const urlPath = `${URL_PATHS.OG_TEMPLATES}/${id}`;
    return httpClient
      .get<OgTemplateDto>({ url: urlPath })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  // eslint-disable-next-line
  static async validateResourceTagList(tags: string[]): Promise<any> {
    return (
      httpClient
        // eslint-disable-next-line
        .post<any>({ url: URL_PATHS.TAG_SERVICE, payload: { tagList: tags } })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          throw Error(error?.data?.errorMessage || error);
        })
    );
  }
}
