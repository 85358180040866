import NotificationsManagerService from "./NotificationsManagerService";
import { WebSocketData, WebSocketStatus } from "../model/WebSocketData";

class ListenerService {
  private readonly sockets: Map<string, WebSocketData>;

  constructor() {
    this.sockets = new Map<string, WebSocketData>();
  }

  setSocketData(
    id: string,
    webSocket: WebSocket,
    status: WebSocketStatus
  ): void {
    this.sockets.set(id, {
      webSocket,
      state: status,
    });
  }

  /**
   * Method that opens a websocket to a given hubname through the notification manager service.
   * @param hubName - Name of the hub to connect to
   * @param func - Function to be executed when a message is received
   * @param identifier -  Identifier suffix that can be added to allow multiple sockets for the same hub
   * @param groupIdSuffix - Group id suffix to be added to the groupIds
   */
  listen(
    hubName: string,
    func: (msg: any) => void,
    identifier = "",
    groupIdSuffix = ""
  ): Promise<WebSocketStatus> {
    const id = hubName + identifier;

    return new Promise((resolve) => {
      if (!this.sockets.get(id)) {
        NotificationsManagerService.getUserHubToken(hubName)
          .then((res) => {
            if (res) {
              const socket = new WebSocket(res.Url);
              socket.onmessage = (messageEvent) => {
                func(messageEvent.data);
              };
              socket.onopen = () => {
                NotificationsManagerService.addUserToGroups(
                  hubName,
                  groupIdSuffix
                )
                  .then(() => {
                    this.setSocketData(id, socket, WebSocketStatus.LISTENING);
                    resolve(WebSocketStatus.LISTENING);
                  })
                  .catch((error) => {
                    this.setSocketData(id, socket, WebSocketStatus.FAILED);
                    resolve(WebSocketStatus.FAILED);
                    throw error;
                  });
              };

              socket.onerror = (error) => {
                this.setSocketData(id, socket, WebSocketStatus.FAILED);
                resolve(WebSocketStatus.FAILED);
                console.error("WebSocket error:", error);
              };
            }
          })
          .catch((error) => {
            this.setSocketData(id, null, WebSocketStatus.FAILED);
            resolve(WebSocketStatus.FAILED);
            console.error("Failed to get user hub token:", error);
          });
      } else {
        resolve(this.sockets.get(id)?.state);
      }
    });
  }

  hasWebSocket(hubName: string, identifier = ""): boolean {
    return this.sockets.has(hubName + identifier);
  }

  /**
   * Method that closes a given websocket identified by the hubName and an optional identifier suffix
   * @param hubName - Name of the hub that to which the socket is open
   * @param identifier - Identifier suffix that can be added to allow multiple sockets for the same hub
   */
  stopListening(hubName: string, identifier = ""): void {
    const id = hubName + identifier;
    const socketData = this.sockets.get(id);
    if (socketData) {
      this.sockets.delete(id);
      socketData?.webSocket?.close();
    } else {
      // eslint-disable-next-line
      console.error("Could not find open websocket.");
    }
  }
}

export default ListenerService;
