import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import "./searchBar.module.css";
import Search from "@mui/icons-material/Search";
import { InputBase } from "@mui/material";
function SearchBar(props) {
    const { searchCallback, dataCr = "search-bar", placeholder = "Search...", } = props;
    return (_jsxs("div", Object.assign({ className: "config-search-bar-main col-10", "data-cr": dataCr }, { children: [_jsx(InputBase, { sx: { width: "100%", fontSize: "14px" }, placeholder: placeholder, "data-cr": `${dataCr}-input`, onChange: (e) => { var _a; return searchCallback(((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) || ""); } }), _jsx(Search, { sx: { color: "var(--compass-secondary)" } })] })));
}
export default SearchBar;
