// General
import React, { useCallback, useEffect, useState } from "react";
import { getNewId } from "compass-commons";
// Components
import { SirenRoundedIcon } from "dms-lib";
import IncidentPriorities from "./incidentPriorities/IncidentPriorities";
// Styles
import "./priorities.module.css";
// Services
import { IncidentPriority } from "../../../../models/filters/IncidentPriority";
// Models
import DataExplorerService from "../../../../services/DataExplorerService";

interface PrioritiesProps {
  onChangeCallbackPriorities?: (priorities: Array<string>) => void;
  priorities?: Array<string>;
}

const Priorities = (props: PrioritiesProps): JSX.Element => {
  const { onChangeCallbackPriorities, priorities } = props;
  const [prioritiesData, setPrioritiesData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedPriorities] = useState(priorities);

  // TODO - eventually implement filters
  const showNotImplementedFilters = false;

  const getIncidentPriority = async () => {
    try {
      const prioritiesArr: Array<IncidentPriority> =
        new Array<IncidentPriority>();

      const prioritiesRes = await DataExplorerService.getIncidentPriorities(); // TODO get customer id
      if (prioritiesRes.length > 0) {
        prioritiesRes.forEach((inc) => {
          const incPriority = {} as IncidentPriority;
          incPriority.priority = inc.priority;
          incPriority.value = inc.priority;
          incPriority.id = getNewId();
          incPriority.isChecked =
            selectedPriorities?.indexOf(inc.priority) >= 0;
          prioritiesArr.push(incPriority);
        });
      }
      setPrioritiesData(prioritiesArr);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage("Failed to get incident priorities.");
      setPrioritiesData(null);
    }
  };

  const callbackPriorities = useCallback((prioritiesChecked) => {
    if (onChangeCallbackPriorities) {
      onChangeCallbackPriorities(prioritiesChecked);
    }
  }, []);

  useEffect(() => {
    if (showNotImplementedFilters) {
      getIncidentPriority().then();
    }
  }, []);

  return (
    <div
      style={{
        display: "inline-block",
        width: "100%",
      }}
    >
      <div className="row">
        <div className="col align-self-start">
          <i className="compass-component-icon">
            <SirenRoundedIcon />
          </i>
        </div>
        <div className="col align-self-end" />
      </div>
      {showNotImplementedFilters ? (
        <IncidentPriorities
          incidentPriorities={prioritiesData}
          onChangeCallback={callbackPriorities}
        />
      ) : (
        <div />
      )}
      {showNotImplementedFilters && errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default Priorities;
