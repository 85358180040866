// General
import React from "react";
import { useI18n } from "compass-commons";
import { Control, Controller } from "react-hook-form";
// Styles
import "../behaviourProperties.module.css";
// Components
import { AutoComplete, OptionType } from "dms-lib";
// Models
import { FormErrors } from "../../../../subsystemConfigurator/components/connectionPropertiesPanel/models/SubsystemConnectionDataTypes";
import { FormRuleDto } from "../../../../../models/incidents/form/FormRuleDto";
import { OgTemplateDto } from "../../../../../models/ogTemplate/OgTemplateDto";

interface OGTemplateOption extends OptionType {
  name: string;
}

interface BehaviourPropertiesOGTemplateProps {
  ruleId: string;
  readOnlyMode: boolean;
  control: Control<FormRuleDto, any>;
  errors?: FormErrors<FormRuleDto>;
  ogTemplates: OgTemplateDto[];
  loading: boolean;
  dataCr?: string;
  handleSelectOperatorGuide?: (ogTemplate: OgTemplateDto) => void;
}

const BehaviourPropertiesOGTemplate = (
  props: BehaviourPropertiesOGTemplateProps
): JSX.Element => {
  const { t: translate } = useI18n();

  const {
    ogTemplates,
    dataCr = "behaviour-properties-template",
    errors,
    ruleId,
    readOnlyMode,
    control,
    loading,
    handleSelectOperatorGuide,
  } = props;

  return (
    <div className="config-incidents__properties__og-template config-incidents__properties__input config-incidents__properties__dropdown">
      <span>
        {translate("behaviors.propertyFieldsDescription.ogTemplate")}:
      </span>
      <div
        className="config-incidents__properties__input-controller"
        id="input-wrapper"
      >
        <Controller
          name="behaviour.ogTemplate"
          control={control}
          render={({ field: { onChange, ...field } }) => {
            return (
              <AutoComplete<OGTemplateOption>
                {...field}
                value={field.value as OGTemplateOption}
                dataCr={dataCr}
                options={ogTemplates}
                getOptionLabel={(option) => option.name}
                id={`behaviour-property-og-template-${ruleId}`}
                disabled={readOnlyMode}
                loading={loading}
                placeholder={translate(
                  "behaviors.propertyFieldsDescription.ogTemplatePlaceholder"
                )}
                onChangeCallback={(data: OgTemplateDto, _e) => {
                  onChange(data);
                  handleSelectOperatorGuide?.(data);
                }}
                error={!!errors.behaviour?.ogTemplate}
                errorMessage={translate(
                  errors.behaviour?.ogTemplate?.message ||
                    errors.behaviour?.ogTemplateId?.message ||
                    errors.behaviour?.message,
                  { label: translate("behaviors.fields.ogTemplate") }
                )}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(BehaviourPropertiesOGTemplate);
