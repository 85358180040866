// General
import React, { useEffect, useState } from "react";
import { useI18n } from "compass-commons";
// Material UI
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { TextField } from "dms-lib";
import "./clearIncidentDialogContent.module.css";
import { ClearIncidentRequestDTO } from "../../../../models/supervisorDashboard/ClearIncidentRequestDTO";

// utils
import { COMMENTS_MAXIMUM_CHARACTERS } from "../../../../utils/Constants";

interface ClearIncidentDialogContentProps {
  commentCallback: (string) => void;
  clearIncident?: ClearIncidentRequestDTO;
}

const ClearIncidentDialogContent = (
  props: ClearIncidentDialogContentProps
): JSX.Element => {
  const { commentCallback, clearIncident = null } = props;
  const { t: translate } = useI18n();
  const [comment, setComment] = useState("");
  const onCommentTextChangeHandler = (event) => {
    setComment(
      event.target.value.substring(0, COMMENTS_MAXIMUM_CHARACTERS - 1)
    );
  };

  useEffect(() => {
    commentCallback(comment);
  }, [comment]);

  return (
    <>
      <div className="clear-incident-dialog-content">
        <div className="clear-incident-dialog-content-header">
          <ReportProblemIcon sx={{ color: "red", fontSize: 44 }} />
          <span>
            {clearIncident?.incidentId
              ? translate("supervisor.clearPopup.title.singleIncident")
              : translate(
                  "supervisor.clearPopup.title.multipleIncidentsWithoutCount"
                )}
          </span>
        </div>
        <div className="clear-incident-dialog-content-comment">
          <TextField
            fullWidth
            className="clear-incident-dialog-content-comment-field"
            value={comment}
            id="fullWidth"
            placeholder={translate("supervisor.clearPopup.placeHolder")}
            onChange={onCommentTextChangeHandler}
            multiline
            rows={3}
            data-cr="clear-incident-delete-comment"
          />
        </div>
      </div>
    </>
  );
};

export default ClearIncidentDialogContent;
