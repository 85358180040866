import {
  RESPONSE_VIEW_TABS,
  ResponseViewEvents,
  SwitchTabEvent,
} from "@msicie/public-types";

// eslint-disable-next-line import/prefer-default-export
export const SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG =
  "b_showGenericErrorMessages";

export const MONITOR_SWITCH_TAB_DATA_EXPLORER: SwitchTabEvent = {
  type: ResponseViewEvents.SWITCH_TAB,
  payload: RESPONSE_VIEW_TABS.DATA_EXPLORER,
};

export const PDF_STYLES = `
html {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

body {
  margin: 24px !important;
}

button[data-cr="dms-button"] {
  display: none !important;
}

.operation-geninfo-card__container,
.operation-geninfo-card__wrapper {
  display: block;
}

.dms-image__photo--noev {
  display: flex;
  max-width: 90%;
  max-height: none;
  overflow: visible;
}

.dms-type-hint {
  white-space: pre-wrap;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 24px;
  }

  body {
    margin: 0 !important;
    overflow-wrap: break-word;
  }
}
`;
